import React, {useState} from 'react';
import styled from "styled-components";

import BgImage from './../../images/bg-bw2.jpg'
import LogoImage from './../../images/SENIB.png'
import axios from "axios";

export default function Help() {
    const [id, setId] = useState('');

    const submitValue = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('id', id);
        bodyFormData.append('name', state.selectedFile.name);
        bodyFormData.append('file', state.selectedFile);

        axios({
            method: 'post',
            url: 'https://api.escoladapalavra.com/api/pdf-upload',
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function () {
            })
            .catch(function () {
            });
    }
    
    const [state, setState] = useState({selectedFile: {name: '',type: ''}});
    
    const onFileChange = event => {
        setState({ selectedFile: event.target.files[0] });
    };
    

    return (
      <MainBox>
        <LogoBox>
            <Logo src={LogoImage}/>
        </LogoBox>
        <FormBox>
            <TitleFirst> Enviar Arquivos </TitleFirst>
            <InputText name="id" placeholder='Id' onChange={e => setId(e.target.value)}/>
            <InputText type="file" onChange={onFileChange} />
            
            <Button onClick={submitValue}> Enviar</Button>
        </FormBox>
      </MainBox>
    );
}

const MainBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-image: url(${BgImage});
`;

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    margin: 0;
    padding: 0;
    width: 50%;
    opacity: 0.9;
`;

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 60vw;
    height: 100vh;
    padding: 0% 4% 0% 4%;


    background: rgb(18,225,176);
    background: -moz-linear-gradient(120deg, rgba(18,225,176,1) 0%, rgba(18,183,225,1) 100%);
    background: -webkit-linear-gradient(120deg, rgba(18,225,176,1) 0%, rgba(18,183,225,1) 100%);
    background: linear-gradient(120deg, rgba(18,225,176,1) 0%, rgba(18,183,225,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12e1b0",endColorstr="#12b7e1",GradientType=1);
`;

const InputText = styled.input`
    margin-bottom: 10px;
    padding: 3%;
    font-size: 1em;

    border: 1px solid white;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    color: white;

    ::placeholder {
        color: white;
    }
    &:focus {
        outline: none;
    }
`;

const Button = styled.button`
    padding: 3%;
    margin-top: 7%;

    border: 1px solid white;
    border-radius: 32px;
    background-color: white;

    color: #12CFC5;
    font-size: 1.2em;
    font-weight: bold;

    cursor: pointer;

    ::placeholder {
        color: white;
    }
`;

const TitleFirst = styled.h1`
    color: white;
    font-size: 1.7em;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
    text-align: center;
`;
