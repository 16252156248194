import React from 'react';
import styled from "styled-components";

import BasicButton from '../../components/micro/BasicButton';

import {
  useHistory
} from "react-router-dom";


export default function HowToUse() {

  let history = useHistory();
   
    const Back = () => {
      history.push("/");
    }

  return (
      <Box>
        <LessonBox>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JXVVcJT8Ong?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </LessonBox>
        <TopBox>
            <BackTopBox onClick={() => {Back()} }>
              <BasicButton>Voltar</BasicButton>
            </BackTopBox>
        </TopBox>
      </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-top: 4%;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
  @media(max-width: 1000px) {
    padding-top: 40%;
  }
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10em;
    margin-right: 2%;
    @media(max-width: 1000px) {
      width: 100%; 
    }
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%
    margin-bottom: 0.5%;
    margin-top: 2em;
`;

const LessonBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding: 2vw;
  width: 50em; 
  height: 28em;
  @media(max-width: 1000px) {
    width: 19em; 
    height: 10em;
    padding: 1vw;
    flex-direction: column;
    justify-content: center;  
    margin: 2vw;
  }
`;

const VideoBox = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%; 
  @media(max-width: 1000px) {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 1.5em;
  }
`;