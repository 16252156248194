import React, { useState } from 'react';
import styled from "styled-components";

import BackButton from '../../components/micro/BackButton';

import New from '../../images/new.png'

export default function NewSubject() {

    const [subjects, setSubjects] = useState({lessons: [{'url':''},{'url':''},{'url':''},{'url':''},{'url':''},{'url':''},{'url':''},{'url':''}], thumb: { data: '' }});
    
    const [newData, setNewData] = useState({lessons: [{'url':''},{'url':''},{'url':''},{'url':''},{'url':''},{'url':''},{'url':''},{'url':''}], thumb: { data: '' }});
    
    const [state, setState] = useState({selectedFile: {name: '',type: ''}});
    
    const onFileChange = async (event) => {
    
        setState({ selectedFile: event.target.files[0] });
        onFileUpload();
    };

    const onFileUpload = async () => {
        newData['thumb']['data'] = await toBase64(state.selectedFile)
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    return (
    <Box>
      <TopBox>
        <AlignLeftItemsDiv>
          <BackTopBox>
            <BackButton>Voltar</BackButton>
          </BackTopBox>
            <h2 style={{ textAlign: 'left', width: '90%' }}> Matéria </h2>
        </AlignLeftItemsDiv>
      </TopBox>
      <GradesBox>
        <GradeBox>
            <Box1>
                <h3> Informações Básicas </h3>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '2%'}}> Nome da Matéria </h5>
                        <InputText name="username" onChange={ e => { newData['name'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', paddingLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '2%'}}> Professor </h5>
                        <InputText name="username" onChange={ e => { newData['teacher'] = e.target.value } } />
                    </div>
                </SubBox2>
            </Box1>
            <Box1>
                <h3> Aulas </h3>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 01 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][0]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 02 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][1]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 03 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][2]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 04 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][3]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 05 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][4]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 06 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][5]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 07 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][6]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 08 </h5>
                        <InputText name="username" onChange={ e => { newData['lessons'][7]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
            </Box1>
            <Box2>
                <div style={{width: '100%', marginRight: '2%'}}>
                    <SubBox3>
                        <h3> Thumbnail </h3>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <label><img alt='' src={subjects['thumb']['data']} style={{width: '20%'}}/></label>
                            <label for="file-upload"><img img alt='' src={New} style={{width: '20%', marginLeft: '3%'}}/></label>
                            <input id="file-upload" type="file" onChange={onFileChange} hidden/>
                        </div>
                    </SubBox3>
                    </div>
            </Box2>
            <SubBox4>
                <h3> Salvar </h3>
                <Button onClick={() => { console.log(newData) } }>Salvar</Button>
            </SubBox4>
        </GradeBox>
      </GradesBox>
    </Box>
    );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 4%;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
`;

const Box1 =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: solid 1px #ADADAD;
    padding: 0.5em 2.5em 1.5em 2.5em;
    margin-bottom: 1.5%;
`;

const Box2 =  styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5%;
    width: 100%;
`;

const SubBox2 =  styled.div`
    display: flex;
    flex-direction: row;
`;

const SubBox3 =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: solid 1px #ADADAD;
    padding: 0.5em 2.5em 1.5em 2.5em;
    margin-bottom: 1.5%;
    height: 90%;
`;

const SubBox4 =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: solid 1px #ADADAD;
    padding: 0.5em 2.5em 1.5em 2.5em;
    margin-bottom: 1.5%;
    height: 38%;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 86vw;
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10%;
    margin-right: 2%
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const GradesBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const GradeBox = styled.table`
  border-collapse: collapse;
  width: 86vw;
  padding: 0% 1.8% 0% 1.8%;
  margin-right: 2%;
`;

const InputText = styled.input`
    font-size: 1em;
    width: 100%;
    height: 1.5em;

    padding-left: 3%;
    border: solid 1px #ADADAD;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    color: #ADADAD;
    font-weight: bold;


    ::value {
        color: #7D7D7D;
    }

    &:focus {
        outline: none;
        background-color: RGBA(255,255,255,0.1);
    }
`;

const Button = styled.button`
    border: none;
    background-color: RGBA(0,0,0,0);

    border: solid 2px #ADADAD;
    height: 3em;
    border-radius: 32px;

    color: #7D7D7D;
    font-size: 1em;
    font-weight: bold;
`;