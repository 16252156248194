import React, {useState, useEffect} from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import BgImage from './../../images/bg-bw2.jpg'
import LogoImage from './../../images/SENIB.png'
import Error from '../../components/macro/Error/index';

import api from "../../services/api";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState('');

    let history = useHistory();

    const submitValue = () => {
        api
        .post("/api/auth/signin",{
            email: email,
            password: password + '1'

        })
        .then((response) => {
            sessionStorage.setItem('session', response.data.accessToken);
            history.push("/");
        }) 
        .catch((err) => {
            setErrorMessage('Usuário ou senha inválidos');
        }); 
    }

    useEffect(() => {
        if (errorMessage !== '') {
            setError(Error(errorMessage));
        }
    }, [errorMessage]);
    
    return (
      <MainBox>
        <LogoBox>
            <Logo src={LogoImage}/>
        </LogoBox>
        <FormBox>
            <TitleFirst> Acesse sua conta </TitleFirst>
            <InputText type="email" name="email" placeholder='Email' onChange={e => setEmail(e.target.value)}/>
            <InputText type="password" name="password"  placeholder='Senha' onChange={e => setPassword(e.target.value)}/>
            
            <Button onClick={submitValue}> Entrar</Button>
            <div style={{textAlign: 'center', width: '100%', margin: '0.7em'}}>{ error }</div>
            <BottomBox>
                <BottomText onClick={() => { history.push("/cadastrar") }}> Crie uma conta </BottomText>
                <BottomText onClick={() => { history.push("/recuperar-senha") }}>
                    <small>Esqueceu sua senha?</small>
                </BottomText>
            </BottomBox>
            

        </FormBox>
      </MainBox>
    );
}

const MainBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #141414;
    
    justify-content: center;
    align-items: center;
    background-image: url(${BgImage});
    background-size: cover;
    z-index: 11;
`;

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    margin: 0;
    padding: 0;
    width: 20%;
    margin-bottom: 5%;

    @media(max-width: 1000px) {
        width: 50%;
        margin-bottom: 1.5em;
    }
`;

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 40em;
    height: 22em;
    padding: 2%;

    background: #ffffff;
    
    border-radius: 32px;

    @media(max-width: 1000px) {
        width: 20em;
        height: 19em;
    }
`;

const InputText = styled.input`
    margin-bottom: 10px;
    padding: 2%;
    font-size: 1em;

    border: 1px solid #0075a4;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    
    color: #0075a4;
    
    margin: 1% 3% 1% 3%;

    width: 96%;

    ::placeholder {
        color: #0075a4;
    }
    &:focus {
        outline: none;
        border: 2px solid #0075a4;
    }

    @media(max-width: 1000px) {
        width: 90%;
        font-size: 0.9em;
    }
`;

const Button = styled.button`
    padding: 2%;
    margin: 1% 3% 1% 3%;

    width: 100%;

    border: 1px solid #0075a4;
    border-radius: 32px;
    background-color: white;

    color: #0075a4;
    font-size: 1.2em;
    font-weight: bold;

    cursor: pointer;

    ::placeholder {
        color: white;
    }
    @media(max-width: 1000px) {
        width: 95%;
        font-size: 0.9em;
    }
`;

const BottomBox = styled.div`
    text-align: center;
    width: 100%;
`;

const BottomText = styled.h1`
    color: #0075a4;
    font-size: 1em;
    padding: 0;
    margin: 0.3em;
    cursor: pointer;
    @media(max-width: 1000px) {
        font-size: 0.9em;
    }
`;

const TitleFirst = styled.h1`
    color: #0075a4;
    font-size: 1.7em;
    margin-bottom: 1.3em;
    text-align: center;
`;