import React, {useState, useEffect} from 'react';
import styled from "styled-components";

import { useHistory, Link } from "react-router-dom";

import api from "../../../services/api";

import LoadingScreen from 'react-loading-screen';

export default function DownloadFile(props) {
  const [file, setFile] = useState('');
  const [cancel, setCancel] = useState(false);
  const [loading, setLoading] = useState(true);
  
  let history = useHistory();

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
      .post("/api/open-pdf", { id: props.id }, config)
      .then((res) => {
        setFile(res.data.file);
        let link = document.querySelector('#save')
        link.target = '_blank'
        link.click()
        setLoading(false)
      }) 
      .catch((err) => {
        // console.log(err);
      });
    if (cancel === true) {
      history.go(0)
    }
  }, [cancel, history]);

  return (
    <LoadingScreen
      loading={loading}
      bgColor='#f1f1f1'
      spinnerColor='#000000'
      textColor='#676767'
      text=''
    >
      <a id='save' download='apostila.pdf' href={file} style={{'display':'none'}} />
    </LoadingScreen>
  );
}   



const SubjectMainBox = styled.div`
  background-color: RGBA(0,0,0,0.5);    
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const SubjectBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 55vw;
  @media(max-width: 1000px) {
    width: 80vw;
  }
`;

const TextSubjectBox = styled.div`
  display: flex;
  flex-direction: column;    
  align-items: center;
  justify-content: center;
  padding: 1em 1.5em 1em 1.5em;
`;

const TitleSubject = styled.h2`
  text-align: center;
  padding: 0 0 0 0;
  font-size: 1.2em;
`;

const TeacherSubject = styled.h2`
  text-align: center;
  padding: 0 0 0 0;
  font-size: 1em;
  margin: 0;
  margin-bottom: 2em;
`;

const SummarySubject = styled.p`
  text-align: center;
  font-size: 1em;
  @media(max-width: 1000px) {
    font-size: 0.8em;
  }
`;

const Image = styled.img`
  height: 60vh;
  @media(max-width: 1000px) {
    height: 0;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;    
  align-items: center;
  justify-content: center;
`;

const SummaryButton = styled.button`
  padding: 0.9em 1.8em 0.9em 1.8em;
  margin: 1em 0.5em 0 0.5em;

  color: black;
  font-size: 1em;

  cursor: pointer;


  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 32px 32px 32px 32px;
  background-color: RGBA(255,255,255,1);
  &:hover {
    background-color: RGBA(240,240,240,1);;
    color: RGBA(0,0,0,1);
  }

  @media(max-width: 1000px) {
    font-size: 0.7em;
    margin: 1em 0.4em 0 0.4em;
  }
`;

const SummaryButtonDisable = styled.button`
  padding: 0.9em 1.8em 0.9em 1.8em;
  margin: 1em 0.5em 0 0.5em;

  font-size: 1em;

  border: solid 1px RGBA(0,0,0,0.1);
  border-radius: 32px 32px 32px 32px;
  background-color: RGBA(240,240,240,1);

  color: RGBA(120,120,120,1);

  @media(max-width: 1000px) {
    font-size: 0.7em;
    margin: 1em 0.4em 0 0.4em;
  }
`;

const Division = styled.div`
  display: flex;
  flex-direction: row;    
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 32px;
`;