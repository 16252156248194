import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import { BsFillEyeFill } from "react-icons/bs";

import BasicButton from '../../components/micro/BasicButton';

import api from "../../services/api";

import {
  useHistory,
  useParams,
  Link
} from "react-router-dom";


export default function TestHome() {
    
  let { id } = useParams();
  let history = useHistory();

  const [name, setName] = useState('');

  const openLesson = (lesson) => {
    history.push( '/curso/' + id + '/' + lesson);
  }

  const Back = () => {
    history.push("/curso/" + id);
  }  
  
    const StartTest = () => {
        const config = {
            headers: {
              'Content-Type':'application/json',
              'x-access-token': sessionStorage.getItem('session'),
            }
          }
        api
      .post("/api/start-test", { id: id }, config)
      .then((res) => {
        if (res.data.message == 'Abrir teste') {
          history.push("/avaliacao/" + id);    
        } else if (res.data.message == 'O teste já foi realizado') {
          history.push("/resultado/" + id);    
        }
      }) 
      .catch((err) => {
        // console.log(err);
        history.push("/");
      });
  }

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
      .post("/api/search-lessons", {id: id }, config)
      .then((res) => {
        setName(res.data.name);
      }) 
      .catch((err) => {
        // console.log(err);
        history.push("/");
      });

    }, [history, id]);


  return (
    <Box>
        <SubBox>
            <Title>{name}</Title>
            <Text>Para concluir o curso você precisará realizar um teste de conhecimentos sobre o conteúdo ministrado nas aulas.</Text>
            <Subtitle>Instruções da avaliação</Subtitle>
            <Items>
                <Item>Você tem somente uma tentativa, verifique as respostas antes de enviá-las</Item>
                <Item>A avaliação tem duração máxima de uma hora e não tem duração mínima</Item>
                <Item>O teste poderá ser enviado até 23h59 do dia 28 de outubro </Item>
                <Item>A avaliação tem peso 10, sendo essa a nota máxima </Item>
                <Item>Para obter aprovação é necessário no mínimo 6 </Item>
                
            </Items>
            <ButtonDiv >
                <LinkButton onClick={ () => { Back() } }><BasicButton style={{padding: '0', margin: '0'}}>Voltar</BasicButton></LinkButton>
                <LinkButton onClick={ () => { StartTest() } }><BasicButton style={{padding: '0', margin: '0'}}>Iniciar avaliação</BasicButton></LinkButton>
            </ButtonDiv>
        </SubBox>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const SubBox = styled.div`
  background-color: white;
  padding: 3em;
  padding-right: 6em;
  width: 70%;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(max-width: 1000px) {
    font-size: 1em;
    width: 80%;
    padding: 2em;
    padding-right: 3em;
  }
`;


const Title = styled.h1`
  font-size: 2em;
  margin-top: 0;
  text-align: left;
  @media(max-width: 1000px) {
    font-size: 1.3em;
  }
`;

const Subtitle = styled.h1`
  font-size: 1.5em;
  text-align: left;
  margin-top: 1.5em;
  @media(max-width: 1000px) {
    font-size: 1em;
  }
`;

const Text = styled.p`
  font-size: 1em;
  margin-top: 2em;
  text-align: center;
  @media(max-width: 1000px) {
    font-size: 0.9em;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
  margin-bottom: 2em;
`;

const Item = styled.p`
  margin-top: 0;
  font-size: 1em;
  text-align: center;
  @media(max-width: 1000px) {
    font-size: 0.9em;
  }
`;

const LinkButton = styled.a`
  width: 10em;
  margin-right: 1em;
`;

const VideoBoxTitle = styled.div`
  width: 100%; 
  height: 3em;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 0px 0px 24px 24px;
`;

const VideoTitle = styled.h3`
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 20em;
  margin-top: 1%;
  @media(max-width: 1000px) {
    width: 22em;
    font-size: 0.9em;
  }
`;