import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import api from "../../services/api";

import BasicButton from '../../components/micro/BasicButton';
import BackButton from '../../components/micro/BackButton';

import {
  useHistory,
  Link,
  useParams
} from "react-router-dom";


export default function Result() {
    
  let { id, lesson } = useParams();
  let history = useHistory();

  const [test, setTest] = useState([]);
  const [answers, setAnswer] = useState([]);
  const [grade, setGrade] = useState([]);
  const [name, setName] = useState('');
  const [idGrade, setIdGrade] = useState();

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }
    api
      .post("/api/test-result", { id: id }, config)
      .then((res) => {
        // console.log(res)
        setTest(res.data.test);
        setAnswer(res.data.answers);
        setGrade(res.data.grade);
        setName(res.data.name);
        setIdGrade(res.data.id);
      }) 
      .catch((err) => {
        // console.log(err);
        // history.push("/");
      });

    }, [history, id, lesson]);

    const Back = () => {
      history.push("/");
    }

    const Certified = () => {
      history.push("/certificado/" + idGrade );
    }
    
  return (
    <Box>
      <TestBox>
        <PreButtonBox>
            <ButtonBox>
              <div onClick={() => {Back()}}><BasicButton>Voltar ao menu inicial</BasicButton></div>
            </ButtonBox>
      </PreButtonBox>
        <SubBox>
            <Title>{name}</Title>
            <Text>{ grade < 7 ? 'Infelizmente você não atingiu pontuação suficiente para conclusão do curso de ' + name + '.' : 'Parabéns! Você foi aprovado no curso de ' + name + '.' }</Text>
            <Text>Ficamos felizes por seu esforço e dedicação. Continue firme estudando a palavra de Deus.</Text>
            <Subtitle>Veja como foi seu desempenho na avaliação:</Subtitle>
            <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '2em'}}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <AlternativeLabelCorrect>As respostas corretas serão marcadas em verde ✔</AlternativeLabelCorrect>
                <AlternativeLabelWrong>As respostas erradas serão marcadas em vermelho ✖</AlternativeLabelWrong>
              </div>
            </div>
        </SubBox>
      {test.map((question, index) => {
        return (
            <QuestionBox key={index}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><h4>{index + 1 + '. '}{question.question}</h4><p style={{margin: '0px 0px 0px 0.7%'}}> [{question.weight}]</p></div>
                <AlternativesBox>
                    {Object.keys(question['alternatives']).map((alternative, idx) => {
                        return (
                          <AlternativeBox>
                            { answers[index] == alternative ? <input type='radio' checked/> : <input type='radio' disabled /> } 
                            { question['answer'] == alternative ? <AlternativeLabelCorrect>{question['alternatives'][alternative] + ' ✔'}</AlternativeLabelCorrect> : answers[index] == alternative ? <AlternativeLabelWrong>{question['alternatives'][alternative] + ' ✖'}</AlternativeLabelWrong> : <AlternativeLabel>{question['alternatives'][alternative]}</AlternativeLabel> }
                          </AlternativeBox>
                        );
                    })}
                </AlternativesBox>
            </QuestionBox>
        );
        })}
        <PreButtonBox>
          <ButtonBox>
            { grade >= 6 ? <div onClick={() => {Certified()}}><BasicButton>Emitir certificado</BasicButton></div> : ''}
          </ButtonBox>
          <ButtonBox>
            <div onClick={() => {Back()}}><BasicButton>Voltar ao menu inicial</BasicButton></div>
          </ButtonBox>
      </PreButtonBox>
    </TestBox>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 82vw;
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10%;
    margin-right: 2%
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const TestBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0vw;
  width: 82vw; 
  @media(max-width: 1000px) {
    width: 95%; 
  }
`;

const QuestionBox = styled.div`
    background-color: white;
    padding: 2% 4% 2% 4%;
    margin-bottom: 1em;
`;

const AlternativesBox = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const AlternativeBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 2em;
  margin-bottom: 1em;
`;

const AlternativeLabel = styled.label`
  margin-left: 0.5em;
`;

const AlternativeLabelWrong = styled.label`
  margin-left: 0.5em;
  color: red;
`;

const AlternativeLabelCorrect = styled.label`
  margin-left: 0.5em;
  color: green;
`;

const PreButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84vw;
    margin-bottom: 1em;
    margin-top: 2em;
    @media(max-width: 1000px) {
      width: 100%;
    }
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 30vw;
  margin: 0;
  margin-top: 1%;
  @media(max-width: 1000px) {
    width: 100%;
  }
`;

const SubBox = styled.div`
  background-color: white;
  padding: 3em;
  padding-right: 6em;
  margin-bottom: 1em;
  @media(max-width: 1000px) {
    padding: 2em;
  }
`;


const Title = styled.h1`
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 1em;
  text-align: center;
  @media(max-width: 1000px) {
    font-size: 1.3em;
  }
`;

const Subtitle = styled.h1`
  font-size: 1.2em;
  margin: 0em;
  margin-top: 1.5em;
  text-align: center;
  @media(max-width: 1000px) {
    font-size: 0.9em;
  }
`;

const Text = styled.p`
  font-size: 1em;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: 0.3em;
  @media(max-width: 1000px) {
    font-size: 0.9em;
  }
`;
