import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import api from "../../services/api";

import BackButton from '../../components/micro/BackButton';
import BasicButton from '../../components/micro/BasicButton';
import DownloadFile from '../../components/macro/DownloadFile';

import LoadingScreen from 'react-loading-screen';

import { useMediaQuery } from 'react-responsive';

import parse from 'html-react-parser';

import {
  useHistory,
  Link,
  useParams
} from "react-router-dom";


export default function Lesson() {
    
  let { id, lesson } = useParams();
  let history = useHistory();

  const [openDownload, setOpenDownload] = useState('');
  const [name, setName] = useState('');
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [fullName, setFullName] = useState('');
  const [url, setUrl] = useState('https://player-vz-29b4a734-b9c.tv.pandavideo.com.br/embed/?v=loading');
  const [loading, setLoading] = useState(true);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  
  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }
    api
      .post("/api/open-lesson", { id: id, lesson: lesson}, config)
      .then((res) => {
//            console.log(res)
        setName(res.data.name);
        setUrl('https://player-vz-29b4a734-b9c.tv.pandavideo.com.br/embed/?v=' + res.data.lessons.url)
        setFiles(res.data.lessons.files)
        if ('description' in res.data.lessons){
          setDescription(res.data.lessons.description)
        }
        if ('fullname' in res.data.lessons){
            setFullName(' - ' + res.data.lessons.fullname)
        }
        setLoading(false)
      }) 
      .catch((err) => {
        // console.log(err);
        history.push("/");
      });

      api
      .post("/api/confirm-lesson", { subject: id, confirm: lesson }, config)
      .then((res) => {
      }) 
      .catch((err) => {
        // console.log(err);
        history.push("/");
      });
    }, [history, id, lesson]);

    const OpenFile = (item) => {
      setLoading(true)
      setOpenDownload(<DownloadFile id={item} />)
      setLoading(false)
    }

    const Back = () => {
      history.push("/curso/" + id );
    }

  return (
    <LoadingScreen
    loading={loading}
    bgColor='#f1f1f1'
    spinnerColor='#000000'
    textColor='#676767'
    text=''
    >
      <Box>
        <LessonBox>
          <RightBox style={{ display: isTabletOrMobile ? 'none' : '' }}>
            <BoxBorder>
              <Title> {name} - {lesson} {fullName}</Title>
              <FileBox>
                  <SubTitle>Recursos</SubTitle>
                  <ul>
                  {files.map((item, index) => {
                      return (
                        <li> <ItemList> <FileName> {item['name']} </FileName> <Baixar> <Link onClick={() => {OpenFile(item['id'])}} style={{textDecoration: 'none', color: '#12BDE1'}}> Baixar </Link></Baixar></ItemList> </li>
                      );
                  })}
                  </ul>
              </FileBox>
              <FileBox style={{ display: description == '' ? 'none' : 'block' }}>
                  <SubTitle>Informações complementares</SubTitle>
                  {parse(description)}
              </FileBox>
            </BoxBorder>
            <div style={{display: 'flex' , justifyContent: 'center', marginTop: '1em'}}><div style={{width: '50%'}}>
              <Link onClick={() => {history.push('/forum/' + id)}}><BasicButton>Fórum</BasicButton></Link>
              <BackTopBox onClick={() => {Back()} }><BackButton>Voltar</BackButton></BackTopBox>
            </div></div>
          </RightBox>

          <VideoBox>
            <iframe src={url} allow={"accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"} allowFullScreen={true} width={"100%"} height={"75%"}></iframe>
          </VideoBox>
          <RightBox style={{ display: isTabletOrMobile ? '' : 'none', marginBottom: '1em' }}>
            <BoxBorder>
              <Title> {name} - Aula {lesson} {fullName}</Title>
              <FileBox>
                  <SubTitle>Recursos</SubTitle>
                  <ul>
                  {files.map((item, index) => {
                      return (
                        <li> <ItemList> <FileName> {item['name']} </FileName> <Baixar> <Link onClick={() => {OpenFile(item['id'])}} style={{textDecoration: 'none', color: '#12BDE1'}}> Baixar </Link></Baixar></ItemList> </li>
                      );
                  })}
                  </ul>
              </FileBox>
              <FileBox style={{ display: description == '' ? 'none' : 'block' }}>
                  <SubTitle>Informações complementares</SubTitle>
                  {parse(description)}
              </FileBox>
            </BoxBorder>
            <div style={{display: 'flex' , justifyContent: 'center', marginTop: '1em'}}><div style={{width: '50%'}}>
              <Link onClick={() => {history.push('/forum/' + id)}}><BasicButton>Fórum</BasicButton></Link>
              <BackTopBox onClick={() => {Back()} }><BackButton>Voltar</BackButton></BackTopBox>
            </div></div>
          </RightBox>
        </LessonBox>
      </Box>
      {openDownload}
    </LoadingScreen>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8%;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 94vw;
    @media(max-width: 1000px) {
      font-size: 0.9em;
      margin-top: 1em;
    }
`;

const BackTopBox = styled.div`
    
`;

const MobileBox = styled.div`
  @media(max-width: 1000px) {
    width: 90%; 
    justify-content: center;
    margin-top: 0;
    margin-bottom: 1em;
  }
`;


const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
    
`;

const LessonBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 16px;
  padding: 1em;
  width: 90vw; 
  justify-content: center;
  @media(max-width: 1000px) {
    padding: 1vw;
    width: 90%; 
    height: 100%; 
    flex-direction: column;
    align-items: center;  
    margin: 2vw;
  }
`;

const VideoBox = styled.div` 
  display: flex;
  flex-direction: row;
  width: 45%; 
  justify-content: center;
  align-items: center;
  @media(max-width: 1000px) {
    width: 85%;
    height: 100%;
    margin-top: 1.5em;
  }
`;

const RightBox = styled.div`
    margin-right: 2em;
    margin-top 3em;
    width: 45%; 
    @media(max-width: 1000px) {
      width: 90%; 
      justify-content: center;
      margin-right: 0;
      padding-left: 0%;
    }
`;

const Title = styled.h1`
    text-align: center;
    @media(max-width: 1000px) {
      font-size: 1em;
    }
`;

const SubTitle = styled.h3`
    @media(max-width: 1000px) {
      font-size: 1em;
    }
`;

const BoxBorder = styled.div`
    border: 1px solid RGBA(0,0,0,0.2);
    border-radius: 16px 16px 16px 16px;
`;

const FileBox = styled.div`
  margin: 1em;
    padding: 0.7% 0% 3% 5%; 
    border: 1px solid RGBA(0,0,0,0.2);
    border-radius: 16px 16px 16px 16px;
`;

const FileName = styled.p`
    color: black;
    word-wrap: none;
    font-style: italic;
    padding: 1.1%;
    margin: 0;
`;

const Baixar = styled.p`
    color: #12BDE1;
    word-wrap: none;
    font-weight: 600;
    padding: 1.1%;
    margin: 0;
    padding-left: 1vw;
`;

const ItemList = styled.div`
    display: flex;
    flex-direction: row;
`;