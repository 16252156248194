import React from 'react';
import styled from "styled-components";

export default function Error(error) {
  return (
    <ErrorBox>
        <ErrorMessage>{error}</ErrorMessage>
    </ErrorBox>
  );
}   


const ErrorBox = styled.div`
    justify-content: center;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 50%;
    margin-left: 25%;
    z-index: 11;
`;

const ErrorMessage = styled.p`
    border-radius: 32px;
    border: 1px solid #0075a4;
    color: #0075a4;
    font-size: 0.8em;
    padding: 1% 1% 1% 1%;
    margin: 0;
`;