import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import api from "../../services/api";
import { useHistory, Link, useParams} from "react-router-dom";

import BackButton from '../../components/micro/BackButton';
import BasicButton from '../../components/micro/BasicButton';

import InputMask from "react-input-mask";

import { FaCamera } from "react-icons/fa";

import Temp from '../../images/temperamentos.jpg'
import axios from "axios";

import Resizer from "react-image-file-resizer";

import LoadingScreen from 'react-loading-screen';

export default function UserStudio() {

  let { id } = useParams();

  const [data, setData] = useState({roles:[]});
  const [photo, setPhoto] = useState([]);

  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [city, setCity] = useState('');
  const [roles, setRoles] = useState(0);

  const [loading, setLoading] = useState(true);
  
  const [errorMessage, setErrorMessage] = useState('');

  const [openned, setOpenned] = useState(false);

  let history = useHistory();

  const config = {
    headers: {
      'Content-Type':'application/json',
      'x-access-token': sessionStorage.getItem('session'),
    }
  }

const onFileChange = event => {
  let selectedFile_temp = event.target.files[0];
  
  let selectedFile = Resizer.imageFileResizer(
    selectedFile_temp, // Is the file of the image which will resized.
    512, // Is the maxWidth of the resized new image.
    512, // Is the maxHeight of the resized new image.
    'JPEG', // Is the compressFormat of the resized new image.
    20, // Is the quality of the resized new image.
    0, // Is the degree of clockwise rotation to apply to uploaded image.
    (uri) => {
      var bodyFormData = new FormData();
      // let ext_arr = selectedFile.type.split('/')
      // let nametypefile = ext_arr[ext_arr.length-1]
      // if (nametypefile == 'jpeg') { nametypefile = 'jpg' }
      bodyFormData.append('id', id);
      bodyFormData.append('name', 'profile_' + data.email + '.jpg');
      bodyFormData.append('file', uri);
      axios({
        method: 'post',
        url: 'https://api.escoladapalavra.com/api/admin-photo-upload',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': sessionStorage.getItem('session')
        }
        })
        .then(function (response) {
            history.go(0)
        })
        .catch(function (response) {
        });
    }, // Is the callBack function of the resized new image URI.
    'file', // Is the output type of the resized new image.
    0, // Is the minWidth of the resized new image.
    0 // Is the minHeight of the resized new image.
  );
};

useEffect(() => {
  
      api
        .post("/api/users-admin", {}, config)
        .then((res) => {
          for (let idx in res.data.users) {
            if ( id == res.data.users[idx]['_id'] ) {
              setData(res.data.users[idx]);
              setPhoto(res.data.users_img[id]);
              setRoles(res.data.users[idx].roles.length)
              setLoading(false)
            }
          }
        }) 
        .catch((err) => {
          // console.log(err);
        });
      }, []);
  
  const update = () => {
    if (password === confirmPassword){
      let query = {id: id}
      if (name != ''){
        query['name'] = name
      }
      if (email != ''){
        query['email'] = email
      }
      if (password != ''){
        query['password'] = password
      }
      if (telefone != ''){
        query['telefone'] = telefone
      }
      if (city != ''){
        query['city'] = city
      }
      if (roles != 0){
        query['roles'] = '' + roles
      }

      api
        .post("/api/update-user-admin", query, config)
        .then((res) => {
          setErrorMessage('Dados atualizados com sucesso!');
          history.go(0)
        }) 
        .catch((err) => {
          // console.log(err);
        });
    } else { 
      setErrorMessage('As senhas não correspondem');
    }
    }
  
  const Back = () => {
    history.push("/admin/usuarios");
  }  

  return (
    <LoadingScreen
    loading={loading}
    bgColor='#f1f1f1'
    spinnerColor='#000000'
    textColor='#676767'
    text=''
    >
      <Box>
        <TopBox>
          <AlignLeftItemsDiv>
            <BackTopBox onClick={() => { Back() }}>
              <BasicButton>Voltar</BasicButton>
            </BackTopBox>
              <h2 style={{ textAlign: 'left', width: '90%' }}> Conta </h2>
          </AlignLeftItemsDiv>
        </TopBox>
        <GradesBox>
          <GradeBox>
              <Box1>
                  <SubBox2>
                      <label for='selecao-arquivo'>
                        <InputFile type="file" onChange={onFileChange} id='selecao-arquivo' accept="image/*" />
                        <div style={ { width:'12em', height: '12em', backgroundImage: 'url(' + photo + ')', backgroundSize: 'cover', borderRadius: '150px', marginTop: '0.5em', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <FaCamera color='white' style={{ fontSize: "4em", opacity: "80%"}} />
                        </div>
                      </label>
                      <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center', marginTop: '2%'}}>
                          <h3 style={{marginBottom: 0, marginTop: '1%', textAlign: 'center'}}> {data['name']}  </h3>
                          <p style={{marginBottom: 0, marginTop: '1%', textAlign: 'center'}}> {data['email']}</p>
                          
                      </div>
                  </SubBox2>
              </Box1>
              <Box1>
                  <SubBox2>
                      <div style={{width: '90%'}}>
                          <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Nome completo </h5>
                          <InputText name="username" placeholder={data['name']} onChange={ e => { setName(e.target.value) } } />
                      </div>
                      <div style={{width: '90%'}}>
                          <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Telefone </h5>
                          <InputMask name="telefone" placeholder={data['telefone']} onChange={ e => { setTelefone(e.target.value) } } mask="(99) 99999-9999" style={{ fontSize: '1em', width: '100%', height: '2em', paddingLeft: '3%', border: 'solid 1px #ADADAD', borderRadius: '32px', backgroundColor: 'RGBA(0,0,0,0)', color: '#ADADAD', fontWeight: 'bold' }}/>
                          {/* <InputText name="telefone" placeholder={data['telefone']} onChange={ e => { setTelefone(e.target.value) } } /> */}
                      </div>
                      <div style={{width: '90%'}}>
                          <h5 style={{marginTop: '2%', marginBottom: '1%'}}> E-mail </h5>
                          <InputText name="username" placeholder={data['email']} onChange={ e => { setEmail(e.target.value) } } />
                      </div>
                      <div style={{width: '90%'}}>
                          <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Senha </h5>
                          <InputText type="password" placeholder="•••••••••••••••" onChange={ e => { setPassword(e.target.value + '1') } } />
                      </div>
                      <div style={{width: '90%'}}>
                          <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Confimar senha </h5>
                          <InputText type="password" placeholder="•••••••••••••••" onChange={ e => { setConfirmPassword(e.target.value + '1') } } />
                      </div>
                      <div style={{width: '90%'}}>
                          <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Cidade </h5>
                          <InputText type="city" placeholder={data['city']} onChange={ e => { setCity(e.target.value) } } />
                      </div>
                  </SubBox2>
              </Box1>
              <SubBox4>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                      <div style={{width: '100%', margin: '1%'}}><Button style={ roles == 1 ? { backgroundColor: '#ffffff', width: '100%' } : { backgroundColor: '#f0f0f0', width: '100%' } } onClick={() => { setRoles(1) } } > Aluno </Button></div>
                      <div style={{width: '100%', margin: '1%'}}><Button style={ roles == 2 ? { backgroundColor: '#ffffff', width: '100%' } : { backgroundColor: '#f0f0f0', width: '100%' } } onClick={() => { setRoles(2) } } > Professor </Button></div>
                      <div style={{width: '100%', margin: '1%'}}><Button style={ roles == 3 ? { backgroundColor: '#ffffff', width: '100%' } : { backgroundColor: '#f0f0f0', width: '100%' } } onClick={() => { setRoles(3) } } > Admin </Button></div>
                  </div>
              </SubBox4>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2em' }}>
                <div style={{ width: '10em', display: 'flex', justifyContent: 'center' }}>
                  <Link style={{width: '100%'}} onClick={() => {update()}}>
                    <BasicButton>Salvar</BasicButton>
                  </Link>
                </div>
              </div>
              
          </GradeBox>
        </GradesBox>
      </Box>
    </LoadingScreen>
  );
}   

const Box = styled.div`
display: flex;
flex-direction: column;
padding-left: 2%;
padding-right: 2%;
padding-top: 2.5em;
padding-bottom: 0%;
align-items: center;
justify-content: center;
`;

const Box1 =  styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  border: solid 1px #ADADAD;
  padding: 2.5em 0 2.5em 0;
  margin-bottom: 1.5%;
`;

const Box2 =  styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5%;
  width: 100%;
`;

const SubBox2 =  styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubBox3 =  styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  border: solid 1px #ADADAD;
  padding: 0em 2.5em 1.5em 2.5em;
  margin-bottom: 1.5%;
`;

const SubBox4 =  styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  border: solid 1px #ADADAD;
  padding: 0.5em 2.5em 1.5em 2.5em;
  margin-bottom: 1.5%;
  height: 38%;
`;

const AlignLeftItemsDiv = styled.div`
  display: flex;
  width: 50vw;
  @media(max-width: 1000px) {
    width: 90vw;
  }
`;

const BackTopBox = styled.div`
  display: flex;
  align-self: center;
  width: 10%;
  margin-right: 2%;
  @media(max-width: 1000px) {
    width: 60vw;
  }
`;

const TopBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5%;
`;

const GradesBox = styled.div`
display: flex;
flex-direction: column;
`;

const GradeBox = styled.table`
border-collapse: collapse;
width: 50vw;
padding: 0% 1.8% 0% 1.8%;
margin-right: 2%;
@media(max-width: 1000px) {
  width: 90vw;
}
`;

const InputText = styled.input`
  font-size: 1em;
  width: 100%;
  height: 2em;

  padding-left: 3%;
  border: solid 1px #ADADAD;
  border-radius: 32px;
  background-color: RGBA(0,0,0,0);
  color: #ADADAD;
  font-weight: bold;
`;

const InputFile = styled.input`
  display: none;
`;

const Button = styled.button`
  border: none;
  background-color: RGBA(0,0,0,0);

  border: solid 2px #ADADAD;
  height: 3em;
  border-radius: 32px;

  color: #7D7D7D;
  font-size: 1em;
  font-weight: bold;
`;