import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import api from "../../services/api";
import { useHistory, useParams } from "react-router-dom";

import BackButton from '../../components/micro/BackButton';

import New from '../../images/new.png'

import axios from "axios";

import Resizer from "react-image-file-resizer";
import BasicButton from '../../components/micro/BasicButton';

export default function SubjectStudio() {

    const [data, setData] = useState([]);
    const [fd, setFD] = useState();

    const [subjects, setSubjects] = useState({open: false, name: '', teacher: '', lessons: [{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''}], thumb: { data: '' }});
    
    const [newData, setNewData] = useState({open: false, name: '', teacher: '', lessons: [{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''},{'url':'', thumbnail:''}], thumb: { data: '' }});

    const [openned, setOpenned] = useState(false);

    const [img, setImg] = useState('');

    let history = useHistory();
    let { id } = useParams();

    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    useEffect(() => {
  
      api
        .post("/api/subjects-admin", {}, config)
        .then((res) => {
            for (let idx in res.data.subjects) {
                if ( id == res.data.subjects[idx]['_id'] ) {
                    setSubjects(res.data.subjects[idx]);
                    setImg(res.data.subjects_img[idx]);
                    newData['name'] = res.data.subjects[idx]['name']
                    newData['teacher'] = res.data.subjects[idx]['teacher']
                    newData['lessons'] = res.data.subjects[idx]['lessons']
                    newData['thumb']['data'] = res.data.subjects[idx]['thumb']['data']
                    setOpenned(res.data.subjects[idx]['open'])
                }
            }
        }) 
        .catch((err) => {
          // console.log(err);
        });
      }, []);
    
    const [state, setState] = useState({selectedFile: {name: '',type: ''}});
    
    const onFileChange = event => {
        let selectedFile_temp = event.target.files[0];
        
        let selectedFile = Resizer.imageFileResizer(
          selectedFile_temp, // Is the file of the image which will resized.
          768, // Is the maxWidth of the resized new image.
          768, // Is the maxHeight of the resized new image.
          'JPEG', // Is the compressFormat of the resized new image.
          20, // Is the quality of the resized new image.
          0, // Is the degree of clockwise rotation to apply to uploaded image.
          (uri) => {
            var bodyFormData = new FormData();
            // let ext_arr = selectedFile.type.split('/')
            // let nametypefile = ext_arr[ext_arr.length-1]
            // if (nametypefile == 'jpeg') { nametypefile = 'jpg' }
            bodyFormData.append('name', 'subject_' + id + '.jpg');
            bodyFormData.append('file', uri);
            bodyFormData.append('id', id);
            setFD(bodyFormData)
          }, // Is the callBack function of the resized new image URI.
          'file', // Is the output type of the resized new image.
          0, // Is the minWidth of the resized new image.
          0 // Is the minHeight of the resized new image.
        );
      };

    const deleteSubject = () => {
        api
        .post("/api/delete-subject", {id: id}, config)
        .then((res) => {
            history.push("/admin")
        }) 
        .catch((err) => {
          // console.log(err);
        });
    }

    const saveSubject = () => {
        newData['open'] = openned
        api
        .post("/api/update-subject", {open: newData['open'], id: id, name: newData['name'], teacher: newData['teacher'], lessons: newData['lessons'], thumb: newData['thumb']['data']}, config)
        .then((res) => {
            history.push("/admin/cursos")
        }) 
        .catch((err) => {
          // console.log(err);
        });
        console.log(fd)
        if (fd != undefined) {
            axios({
                method: 'post',
                url: 'https://api.escoladapalavra.com/api/subject-photo-upload',
                data: fd,
                headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': sessionStorage.getItem('session')
                }
                })
                .then(function (response) {
                    history.go(0)
                })
                .catch(function (response) {
            });
        }
    }

    const Back = () => {
     history.push("/admin/cursos");
    }  

    return (
    <Box>
      <TopBox>
        <AlignLeftItemsDiv>
            <BackTopBox onClick={() => { Back() }}>
                <BasicButton>Voltar</BasicButton>
            </BackTopBox>
            <h2 style={{ textAlign: 'left', width: '90%' }}> Matéria </h2>
        </AlignLeftItemsDiv>
      </TopBox>
      <GradesBox>
        <GradeBox>
            <Box1>
                <h3> Informações Básicas </h3>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Nome da Matéria </h5>
                        <InputText name="username" placeholder={subjects['name']} onChange={ e => { newData['name'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', paddingLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Rodada </h5>
                        <InputText name="username" placeholder={subjects['round']} onChange={ e => { newData['round'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Nome do Professor </h5>
                        <InputText name="username" placeholder={subjects['teacher']['name']} onChange={ e => { newData['teacher']['name'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', paddingLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Id do Professor </h5>
                        <InputText name="username" placeholder={subjects['teacher']['obj']} onChange={ e => { newData['teacher']['obj'] = e.target.value } } />
                    </div>
                </SubBox2>
            </Box1>
            <Box1>
                <h3> Aulas </h3>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 01 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][0]? subjects['lessons'][0]['url'] : ''} onChange={ e => { newData['lessons'][0]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 02 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][1]? subjects['lessons'][1]['url'] : ''} onChange={ e => { newData['lessons'][1]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 03 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][2]? subjects['lessons'][2]['url'] : ''} onChange={ e => { newData['lessons'][2]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 04 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][3]? subjects['lessons'][3]['url'] : ''} onChange={ e => { newData['lessons'][3]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 05 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][4]? subjects['lessons'][4]['url'] : ''} onChange={ e => { newData['lessons'][4]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 06 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][5]? subjects['lessons'][5]['url'] : ''} onChange={ e => { newData['lessons'][5]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
                <SubBox2>
                    <div style={{width: '47%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 07 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][6]? subjects['lessons'][6]['url'] : ''} onChange={ e => { newData['lessons'][6]['url'] = e.target.value } } />
                    </div>
                    <div style={{width: '47%', marginLeft: '3%'}}>
                        <h5 style={{marginTop: '2%', marginBottom: '1%'}}> Aula 08 </h5>
                        <InputText name="username" placeholder={subjects['lessons'][7]? subjects['lessons'][7]['url'] : ''} onChange={ e => { newData['lessons'][7]['url'] = e.target.value } } />
                    </div>
                </SubBox2>
            </Box1>
            <Box2>
                <div style={{marginRight: '2%', width: '100%', height: '30%'}}>
                    <SubBox3>
                        <h3> Thumbnail </h3>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <label style={{width: '35%', cursor: 'pointer'}} for="file-upload"><img img alt='' src={New} style={{width: '100%'}}/></label>
                            <label style={{width: '35%'}}><img alt='' src={img} style={{width: '100%', marginLeft: '25%'}} /></label>
                            <input id="file-upload" type="file" onChange={onFileChange} hidden/>
                        </div>
                    </SubBox3>
                </div>
                <div style={{width: '100%', height: '70%'}}>
                    <SubBox4>
                        <h3> Teste </h3>
                        <Button onClick={() => { console.log(newData) } }>Alterar Teste</Button>
                    </SubBox4>
                    <SubBox4>
                        <h3> Visibilidade </h3>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: '48%', margin: '1%'}}><Button style={ openned == true ? { backgroundColor: '#ffffff' } : { backgroundColor: '#f0f0f0' } } onClick={() => { setOpenned(true) } } > Aberto </Button></div>
                            <div style={{width: '48%', margin: '1%'}}><Button style={ openned == false ? { backgroundColor: '#ffffff' } : { backgroundColor: '#f0f0f0' } } onClick={() => { setOpenned(false) } } > Fechado </Button></div>
                        </div>
                    </SubBox4>
                    <SubBox4>
                        <h3> Deletar </h3>
                        <Button onClick={() => { deleteSubject() }} >Excluir Matéria</Button>
                    </SubBox4>
                </div>
            </Box2>
            <SubBox4>
                <h3> Salvar </h3>
                <Button onClick={() => { saveSubject() } }>Salvar</Button>
            </SubBox4>
        </GradeBox>
      </GradesBox>
    </Box>
    );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 4%;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
`;

const Box1 =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: solid 1px #ADADAD;
    padding: 0.5em 2.5em 1.5em 2.5em;
    margin-bottom: 1.5%;
`;

const Box2 =  styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5%;
    width: 100%;
`;

const SubBox2 =  styled.div`
    display: flex;
    flex-direction: row;
`;

const SubBox3 =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: solid 1px #ADADAD;
    padding: 0.5em 2.5em 1.5em 2.5em;
    margin-bottom: 1.5%;
`;

const SubBox4 =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: solid 1px #ADADAD;
    padding: 1.3em 2.5em 1.5em 2.5em;
    margin-bottom: 1.5%;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 86vw;
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10%;
    margin-right: 2%
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const GradesBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const GradeBox = styled.table`
  border-collapse: collapse;
  width: 86vw;
  padding: 0% 1.8% 0% 1.8%;
  margin-right: 2%;
`;

const InputText = styled.input`
    font-size: 0.8em;
    width: 100%;
    height: 2.2em;

    margin-bottom: 0.4em;

    padding-left: 3%;
    border: solid 1px #ADADAD;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    color: #ADADAD;
    font-weight: 600;


    ::value {
        color: #7D7D7D;
    }

    &:focus {
        outline: none;
        background-color: RGBA(255,255,255,0.1);
    }
`;

const Button = styled.button`
    border: none;
    background-color: RGBA(0,0,0,0);

    border: solid 2px #ADADAD;
    height: 3em;
    width: 100%;
    border-radius: 32px;

    color: #7D7D7D;
    font-size: 0.8em;
    font-weight: bold;
`;


{/* {subjects.map((subject) => {
return (
    <tr key={subject._id}>
    <Cell style={ { height: '3vw' } }> { subject.name } </Cell>
    <Cell> { subject.teacher } </Cell>
    <Cell> { subject.round } </Cell>
    <Cell onClick={() => { history.push("/admin/materias/" + subject._id) } }> Acessar </Cell>
    </tr>
);
})} */}