import React from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import BgImage from './../../images/bg.jpg';
import LogoEscola from './../../images/SENIB.png';
import LogoIgreja from './../../images/logo.png';

export default function FirstTime() {

    let history = useHistory();

    const Access = () => {
        history.push("/entrar");
    }
    
    return (
      <MainBox>
        <IconeBox>
            <Icone src={LogoEscola}/>
        </IconeBox>
        <FormBox>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/tipTg2-fuLk?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </FormBox>
        <TextFirstDiv>
            <TextFirstBottomWhite> PLATAFORMA DE ENSINO DA </TextFirstBottomWhite>
            <TextFirstBottomBlue> NOVA IGREJA BATISTA DE CASCAVEL </TextFirstBottomBlue>
        </TextFirstDiv>
        <LogoBox>
            <Logo src={LogoIgreja}/>
        </LogoBox>
        <Info1>
            <Info1Div>
                <Info1Text1> Aprenda no seu tempo. </Info1Text1>
                <Info1Text2> 100% online, 100% gratuito. </Info1Text2>
                <AccessButton1 onClick={() => { Access() }}>Acesse a Plataforma</AccessButton1>
            </Info1Div>
        </Info1>
        <Info2>
            <DepoimentosDiv>
                <DepoimentoDiv>
                    <Info1Text1> Onde Estamos </Info1Text1>
                    <br/>
                    <TextFirstBottomWhite> <a style={{ decoration:'none', color: 'white',  }} href='https://goo.gl/maps/K7WXtwvnQSz1pvTc7' > Rua Carlos de Carvalho, 3289. <br/> Cascavel - Paraná, Brasil. <br/> CEP 85801-130. </a> </TextFirstBottomWhite>
                </DepoimentoDiv>
                <DepoimentoDiv>
                    <Info1Text1> Nosso site </Info1Text1>
                    <br/>
                    <TextFirstBottomWhite> <a style={{ decoration:'none', color: 'white',  }} href='http://www.nibcascavel.org' > www.nibcascavel.org </a> </TextFirstBottomWhite>
                </DepoimentoDiv>
                <DepoimentoDiv>
                    <Info1Text1> Fale conosco </Info1Text1>
                    <br/>
                    <TextFirstBottomWhite> <a style={{ decoration:'none', color: 'white',  }} href='mailto:contato@escoladapalavra.com' > contato@escoladapalavra.com </a> </TextFirstBottomWhite>
                </DepoimentoDiv>
            </DepoimentosDiv>
        </Info2>
        <AccessButton onClick={() => { Access() }}>Acesse a Plataforma</AccessButton>
      </MainBox>
    );
}

const MainBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    background: #141414;
    
    align-items: center;
    background-color: black;
    background-size: cover;
    z-index: 11;
    @media(max-width: 1000px) {
        width: 100%;
    }
`;

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    margin: 0;
    padding: 0;
    width: 7em;
    margin-top: 3em;
    @media(max-width: 1000px) {
        margin-top: 3em;
        margin-bottom: 2em;
        width: 6em;
    }
`;

const IconeBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icone = styled.img`
    margin: 0;
    padding: 0;
    width: 9em;
    margin-top: 1em;
    @media(max-width: 1000px) {
        margin-top: 1em;
        margin-bottom: 0em;
        width: 7em;
    }
`;

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 35em;
    height: 19em;
    padding: 1%;
    
    margin: 4em;

    @media(max-width: 1000px) {
        width: 19em;
        height: 10em;
    }
`;

const Info1 = styled.div`
    width: 100%;
    height: 50em;
    display: flex;
    align-items: end;
    justify-content: start;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${BgImage});
    @media(max-width: 1000px) {
        height: 24em;
        background-image: none;
        background-color: black;
        align-items: center;
        justify-content: center;
    }
`;

const Info1Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 8em;
    margin-bottom: 10em;
    @media(max-width: 1000px) {
        margin: 0;
        width: 100%;
        align-items: center;
    }
`;

const Info1Text1 = styled.p`
    color: #006AD5;
    font-size: 2em;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    @media(max-width: 1000px) {
        font-size: 1.2em;
    }
`;

const Info1Text2 = styled.p`
    color: white;
    font-size: 2em;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    @media(max-width: 1000px) {
        font-size: 1.2em;
    }
`;

const Info2 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Info2Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 8em;
    margin-top: 0em;
    margin-bottom: 2em;
    @media(max-width: 1000px) {
        margin: 0;
        align-items: center;
    }
`;

const DepoimentosDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    margin: 3em;
    @media(max-width: 1000px) {
        flex-direction: column;
    }
`;

const DepoimentoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 3em;
    width: 20em;
    @media(max-width: 1000px) {
        width: 80%;
        align-items: center;
    }
`;

const TextFirstBottomWhite = styled.p`
    color: white;
    font-size: 1em;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    @media(max-width: 1000px) {
        font-size: 1em;
        text-align: center;
    }
`;

const TextFirstBottomBlue = styled.p`
    color: #006AD5;
    font-size: 1em;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    @media(max-width: 1000px) {
        font-size: 1em;
        text-align: center;
    }
`;

const TextFirstDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AccessButton = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 1px solid black;
    border-radius: 32px 32px 32px 32px;
    background-color: #006AD5;
    border: 1px solid #006AD5;
    font-size: 1.2em;
    padding: 0.5em;
    color: black;
    font-weight: bold;

    &:hover {
        background-color: black;
        color: #006AD5;
        border: 1px solid #006AD5;
    }
    cursor: pointer;
    
    @media(max-width: 1000px) {
        display: none;
    }
`;

const AccessButton1 = styled.button`
    border-radius: 1000px;
    background-color: transparent;
    border: 1px solid #006AD5;
    font-size: 1.2em;
    width: 100%;
    padding: 1em;
    margin-top: 0.5em;
    color: #006AD5;
    font-weight: bold;

    &:hover {
        background-color: rgba(255,255,255,0.05);
    }
    cursor: pointer;
    @media(max-width: 1000px) {
        width: 80%;
        margin-bottom: 5em;
        font-size: 1em;
    }
`;