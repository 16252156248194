import React, { useState } from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import api from "../../../services/api";

import BasicButton from '../../micro/BasicButton';

export default function NewSubject() {
  
  const [name, setName] = useState();

  let history = useHistory();

    const config = {
        headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
        }
    }

    const Cancel = () => {
        history.go(0);
    }

    const SendTopic = () => {
        api
        .post("/api/new-subjects-admin", {name: name}, config)
        .then((response) => {
          history.go(0);
        }) 
    }
  
  return (
    <SubjectMainBox>
        <SubjectBox>
            <SubTitle>Nome da matéria</SubTitle>
            <InputTitle onChange={e => setName(e.target.value)} />
            <ButtonBox>
                <LinkButton onClick={() => { Cancel() }}><BasicButton>Fechar</BasicButton></LinkButton>
                <LinkButton onClick={() => { SendTopic() }}><BasicButton>Enviar sua pergunta</BasicButton></LinkButton>
            </ButtonBox>
        </SubjectBox>
      </SubjectMainBox>
  );
}   



const SubjectMainBox = styled.div`
  background-color: RGBA(0,0,0,0.5);    
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
  
`;

const SubjectBox = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  padding: 1em;
  border-radius: 16px;
`;

const InputTitle = styled.input`
  border: solid 1px black;
  border-radius: 16px;
  width: 40vw;
  height: 2vh;
  padding: 0.7em 1.5em 0.7em 1.5em;
  &:focus{
    outline: none;
  }
  @media(max-width: 1000px) {
    width: 80vw;
  }
`;

const InputDescription = styled.textarea`
  border: solid 1px black;
  border-radius: 16px;
  width: 40vw;
  height: 25vh;
  padding: 1.5em;
  &:focus{
    outline: none;
  }
  @media(max-width: 1000px) {
    width: 80vw;
  }
`;

const SubTitle = styled.h3`
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 50vw;
  margin-top: 2%;
  margin-bottom: 2%;
  @media(max-width: 1000px) {
    margin-top: 10%;
    width: 100%;
  }
`;

const LinkButton = styled.div`
  margin-left: 1vw;
  margin-right: 1vw;
`;