import React, { useState } from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import api from "../../../services/api";

import BasicButton from '../../micro/BasicButton';

export default function TopicReply(topic, data) {
  
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  let history = useHistory();

    const config = {
        headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
        }
    }

    const Cancel = () => {
      history.go(0);
    }

    const SendTopic = () => {
      if (description != '') {
        api
        .post("/api/reply-topic",{ id: topic.topic.topic.topic._id, answer: description }, config)
        .then((response) => {
          history.go(0);
        }) 
      }
    }
  
  return (
    <SubjectMainBox>
        <SubjectBox>
          <TopicInfo>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Profile src={topic.topic.topic.user.profile} />
                <h4 style={{margin: '0%', marginTop: '0.8em'}}>{topic.topic.topic.user.name}</h4>
                <ReplyTitle>{new Date(topic.topic.topic.topic.createdAt).toLocaleString("pt-BR")}</ReplyTitle>
              </div>
              <div style={{padding: '0', width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'center', alignItems: 'center'}}>
                <TopicTitle>{topic.topic.topic.topic.title}</TopicTitle>
                <TopicSubTitle>{topic.topic.topic.topic.description}</TopicSubTitle>
              </div>
            </TopicInfo>
              <h4 style={{padding: '0', margin: '0', marginBottom: '0.8em'}}>Resposta</h4>
            <TopicInfo>
              <div style={{width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'center', alignItems: 'center'}}>
                <InputDescription onChange={e => setDescription(e.target.value)} />
              </div>
            </TopicInfo>
            <ButtonBox>
              <LinkButton onClick={() => { Cancel() }}><BasicButton>Fechar</BasicButton></LinkButton>
              <LinkButton onClick={() => { SendTopic() }}><BasicButton>Enviar sua resposta</BasicButton></LinkButton>
            </ButtonBox>
        </SubjectBox>
      </SubjectMainBox>
  );
}   



const SubjectMainBox = styled.div`
  background-color: RGBA(0,0,0,0.5);    
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const SubjectBox = styled.div`
  background-color: #E9E9E9;
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  padding: 1em;
  margin: 0em;
  border-radius: 16px;
`;

const InputTitle = styled.input`
  border-radius: 32px;
  width: 40vw;
  height: 2vh;
  padding: 1vw 1.5vw 1vw 1.5vw;
  &:focus{
    outline: none;
  }
  @media(max-width: 1000px) {
    width: 80vw;
  }
`;

const InputDescription = styled.textarea`
  width: 95%;
  padding: 1.5em;
  &:focus{
    outline: none;
  }
`;

const SubTitle = styled.h3`
`;


const TopicInfo = styled.div`
  align-items: center;
  justify-content: center;
  text-align: left;
  background-color: white;
  width: 40em;
  padding: 0em;
  margin: 0em;
  margin-bottom: 0.8em;
  padding: 1.5em;
  border-radius: 16px;
  @media(max-width: 1000px) {
    width: 15em;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 40vw;
  margin-top: 2%;
  margin-bottom: 2%;
  @media(max-width: 1000px) {
    width: 100%;
  }
`;

const LinkButton = styled.div`
  margin-left: 1vw;
  margin-right: 1vw;
`;

const Profile = styled.img`
    width: 7em;
    height: 7em;
    border-radius: 100px;
`;

const TopicTitle = styled.h3`
    margin: 0;
    padding: 0;
    padding-top: 1%;
    width: 100%;
    text-align: left;
`;

const ReplyTitle = styled.p`
    margin: 0;
    padding: 0;
    margin-top: 0.4em;
    margin-bottom: 1em;
    font-size: 0.8em;
`;

const TopicSubTitle = styled.p`
    margin: 0;
    margin-top: 2%;
    padding: 0;
    padding-bottom: 2%;
    font-size: 0.9em;
    width: 100%;
    text-align: left;
`;