import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';
import './index.css';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL, {}, {
    autoConfig: false,
    debug: false,
  });

  ReactPixel.pageView();
}

ReactDOM.render(
  <App>
    <App />
  </App>,
  document.getElementById('root')
);
