import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import Subscribe from '../../components/macro/Subscribe'

import materia1 from '../../../src/images/materia1.jpg'
import materia2 from '../../../src/images/materia2.jpg'
import bgHome from '../../../src/images/bg-home.jpg'

import api from "../../services/api";
import { useHistory } from "react-router-dom";

import LoadingScreen from 'react-loading-screen';

import { useMediaQuery } from 'react-responsive';

import unstableSourceImage from '../../images/unstable.jpeg'

export default function Home() {

  const [allData, setAllData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [block, setBlock] = useState(true);
  const [unstable, setUnstable] = useState(true);

  let history = useHistory();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  useEffect(() => {

    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
    .get("/api/search-subscribe", config)
    .then((res) => {
      setSubData(res.data.subject);
      setBlock((res.data.subject.length >= 3 ? true : false ))
    })
    .catch((err) => {
      // console.log(err);
      history.push("/inicial");
    });

    api
    .get("/api/search-subject", config)
    .then((res) => {
      setAllData(res.data.subject);
      setLoading(false)
    })
    .catch((err) => {
      // console.log(err);
      history.push("/inicial");
    });
  }, [history]);


  const [sub, setSub] = useState();

  const OpenSub = (data) => {
    data['block'] = block
    setSub(<Subscribe data={data}/>);
  }

  return (
    <LoadingScreen
            loading={loading}
            bgColor='#f1f1f1'
            spinnerColor='#000000'
            textColor='#676767'
            text=''
    >
    <Box>
      <SubBox><InicialBanner src={ bgHome }></InicialBanner></SubBox>
      <Division>
        <SubBox>
          <Title>Comece aqui</Title>
          <Subjects>
              <ThumbBox onClick={() => { history.push('/bem-vindo') }}>
                <Thumb src={materia1} />
              </ThumbBox>
              <ThumbBox onClick={() => { history.push('/como-usar') }}>
                <Thumb src={materia2} />
              </ThumbBox>
          </Subjects>
        </SubBox>

        <SubBox>
          <Title>Meus cursos</Title>
          <Subjects>
          { subData.length == 0 ? <p style={{ height : '31vh' }}>Você ainda não se inscreveu em nenhum curso. Você pode se cadastrar em até 3 cursos. Escolha os que deseja fazer e inscreva-se! </p> : ''}
          {subData.map((item) => {
            return (
              <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
                <Thumb src={ item.thumb } />
              </ThumbBox>
            );
          })}
          </Subjects>
        </SubBox>
      </Division>

      {/* <SubBox>
        <Title>Todos os cursos</Title>
        <AllSubjects>
        {allData.map((item) => {
          return (
            <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
              <Thumb src={item.thumb} />
            </ThumbBox>
          );
        })}
        </AllSubjects>
      </SubBox> */}

      <SubBox>
        <Title>Módulo Integração</Title>
        <AllSubjects>
        {allData.map((item) => {
          if (item.group == 'i'){
            // hiding "Um com Deus" for new subscriptions
            if (item._id !== '61673e4ee0a74662552c2c0b') {
              return (
                  <ThumbBox key={item._id} onClick={() => {
                    OpenSub(item)
                  }}>
                    <Thumb src={item.thumb}/>
                  </ThumbBox>
              );
            }
          }
        })}
        </AllSubjects>
      </SubBox>

      <SubBox>
        <Title>Módulo Crescimento { isTabletOrMobile ? "" : "-" } Relacionamentos</Title>
        <AllSubjects>
        {allData.map((item) => {
          if (item.group == 'cr'){
            return (
              <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
                <Thumb src={item.thumb} />
              </ThumbBox>
            );
          }
        })}
        </AllSubjects>
        </SubBox>
        <SubBox>
        <Title>Módulo Crescimento { isTabletOrMobile ? "" : "-" } Família</Title>
        <AllSubjects>
        {allData.map((item) => {
          if (item.group == 'cf'){
            return (
              <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
                <Thumb src={item.thumb} />
              </ThumbBox>
            );
          }
        })}
        </AllSubjects>
        </SubBox>
        <SubBox>
        <Title>Módulo Crescimento { isTabletOrMobile ? "" : "-" } Livros da Bíblia</Title>
        <AllSubjects>
        {allData.map((item) => {
          if (item.group == 'ce'){
            return (
              <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
                <Thumb src={item.thumb} />
              </ThumbBox>
            );
          }
        })}
        </AllSubjects>
        </SubBox>
        <SubBox>
        <Title>Módulo Crescimento { isTabletOrMobile ? "" : "-" } Outros</Title>
          <AllSubjects>
          {allData.map((item) => {
            if (item.group == 'c'){
              return (
                <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
                  <Thumb src={item.thumb} />
                </ThumbBox>
              );
            }
          })}
        </AllSubjects>
      </SubBox>

      <SubBox>
        <Title>Módulo Maturidade</Title>
        <AllSubjects>
        {allData.map((item) => {
          if (item.group == 'm'){
            return (
              <ThumbBox key={item._id} onClick={() => {OpenSub(item)}}>
                <Thumb src={item.thumb} />
              </ThumbBox>
            );
          }
        })}
        </AllSubjects>
      </SubBox>
      {/* { unstable ? <UnstableImage src={unstableSourceImage} /> : '' }
      { unstable ? <div style={{width: '1000vw', height: '1000vw', position: 'fixed', zIndex: '11', backgroundColor: 'RGBA(0,0,0,0.7)'}} onClick={ () => { setUnstable(false) } }></div> : '' }  */}
      {sub}
    </Box>
    </LoadingScreen>
  );
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5%;
    padding-bottom: 10%;
    justify-content: center;
    align-items: center;
    @media (max-width: 1000px) {
      padding-top: 2em;
    }
`;

const Division = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
`;

const SubBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: fit-content;
  margin: 1em;
  padding: 2.5em;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 32px;
  @media (max-width: 1000px) {
    padding: 1em;
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  margin: 1em;
  margin-top: 0em;
  font-size: 1.7em;
  text-align: left;
  width: 100%;
  @media (max-width: 1000px) {
    width: 80%;
    font-size: 1.2em;
    text-align: center;
  }
`;

const SubTitle = styled.h3`
  margin: 1em;
  margin-top: 2em;
  font-size: 1.3em;
  text-align: left;
  width: 100%;
  @media (max-width: 1000px) {
    font-size: 1em;
    text-align: center;
  }
`;

const Subjects = styled.div`
    display: flex;
    width: 38vw;
    overflow-x: scroll;
    @media (max-width: 1000px) {
      overflow-x: scroll;
      width: 80vw;
    }
`;

const AllSubjects = styled.div`
    display: flex;
    width: 83vw;
    overflow-x: scroll;
    @media (max-width: 1000px) {
      overflow-x: scroll;
      width: 80vw;
    }
`;

const InicialBanner = styled.img`
    display: flex;
    width: 83vw;
    @media (max-width: 1000px) {
      width: 80vw;
    }
`;

const ThumbBox = styled.div`
  padding: 0.2%;
  cursor: pointer;
`;

const Thumb = styled.img`
  height: 35vh;
  border-radius: 16px;
  @media (max-width: 1000px) {
    height: 12em;
  }
`;

const UnstableImage = styled.img`
  z-index: 12;
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -15%;
  margin-left: -15%;
  -webkit-overflow-scrolling:touch;
  border-radius: 32px
`;
