import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import { BsFillEyeFill, BsLockFill } from "react-icons/bs";

import BasicButton from '../../components/micro/BasicButton';

import api from "../../services/api";

import LoadingScreen from 'react-loading-screen';

import Unsubscribe from '../../components/macro/Unsubscribe';

import {
  useHistory,
  useParams,
  Link
} from "react-router-dom";

export default function Subject() {
    
  let { id } = useParams();
  let history = useHistory();

  const [name, setName] = useState('');
  const [allData, setAllData] = useState([]);
  const [progress, setProgress] = useState({});
  const [test, setTest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState([]);
  
  const openLesson = (lesson) => {
    history.push( '/curso/' + id + '/' + lesson);
  }

  const Back = () => {
    history.push("/");
  }  

  const OpenUnsub = () => {
    setSub(<Unsubscribe data={id}/>);
  }

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
      .post("/api/search-lessons", {id: id }, config)
      .then((res) => {
        setName(res.data.name);
        setAllData(res.data.lessons);
        setProgress(res.data.progress);
        setTest(res.data.opentest);
        setLoading(false)
      }) 
      .catch((err) => {
        // console.log(err);
        history.push("/");
      });

    }, [history, id]);

  return (
    <LoadingScreen
      loading={loading}
      bgColor='#f1f1f1'
      spinnerColor='#000000'
      textColor='#676767'
      text=''
    >
      <Box>
        <SubBox>
          <Title>{name}</Title>
          <LessonsBox>
            {allData.map((item, index) => {
              if (item['thumbnail'] !== 'undefined') {
                let eye = 'none'
                let lock = 'none'
                if (progress[index+1] === true){
                  eye = ''
                } 
                if (progress[index] == false){
                  lock = ''
                } 
                return (
                  <VideoBox onClick={() => { if (progress[index] === true || index == 0){ openLesson(index + 1) } }}>
                    <VideoBoxTitle><VideoTitle>AULA {index + 1}</VideoTitle></VideoBoxTitle>
                    <BsFillEyeFill color='RGBA(50,50,50,1)' style={{ display: eye, fontSize: "1.5em", zIndex: '2' }} />
                    <BsLockFill color='RGBA(50,50,50,1)' style={{ display: lock, fontSize: "1.3em", zIndex: '2' }} />
                  </VideoBox>
                );
              } else { 
                return ''
              }
            })}
          </LessonsBox>
          <ButtonBox>
            <Link style={{margin: "2%", width: '30%'}} onClick={() => {Back()}}><BasicButton>Voltar</BasicButton></Link>
            <Link style={{margin: "2%", width: '30%'}} onClick={() => {history.push('/forum/' + id)}}><BasicButton>Fórum</BasicButton></Link>
            { ((progress['8'] == true) && (test)) ?  <Link style={{margin: "2%", width: '30%'}} onClick={() => {history.push('/iniciar-avaliacao/' + id)}}><BasicButton>Avaliação</BasicButton></Link> : '' }
          </ButtonBox>
          <Link style={{textDecoration: 'underline', color: '#555555'}} onClick={() => {OpenUnsub()}}><p>Cancelar inscrição</p></Link>
        </SubBox>
      </Box>
      {sub}
    </LoadingScreen>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-top: 5%;
  padding-bottom: 10%;
  align-items: center;
  justify-content: center;
`;

const SubBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background-color: white;
  border-radius: 16px;
  border: solid 1px RGBA(0,0,0,0.2);
  @media(max-width: 1000px) {
    margin: 3em;
    width: 70%;
  }
`;


const Title = styled.h1`
  font-size: 1.7em;
  text-align: center;
  @media(max-width: 1000px) {
    font-size: 1.1em;
  }
`;

const LessonsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 1000px) {
    flex-direction: column;
  }
`;

const VideoBox = styled.div`
  width: 40em; 
  margin: 0.2em;
  padding: 0 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 32px;
  @media(max-width: 1000px) {
    width: 14em; 
    height: 1.8em; 
    padding: 0 1em;
    margin-bottom: 0.5em;
  }
  cursor: pointer;
`;

const Thumbnail = styled.div`
  width: 20vw; 
  height: 14.2vw; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1.5vw;
  @media(max-width: 1000px) {
    width: 50vw; 
    height: 30vw; 
  }
`;

const VideoBoxTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
`;

const VideoTitle = styled.p`
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  font-weight: 500;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 40vw;
  margin-top: 1%;
  @media(max-width: 1000px) {
    width: 80vw;
    font-size: 0.9em;
  }
`;