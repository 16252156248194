import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import api from "../../services/api";

import Background from "../../images/bg-certified.jpg";
import Logo from "../../images/logo-certified.png";
import Logo2 from "../../images/logo2.png";
import Assinatura from "../../images/assinatura.png";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {
  useParams
} from "react-router-dom";


export default function Certified() {
    
  let { id } = useParams();

  const [data, setData] = useState({name: '', subject: ''});

  const exportPdf = () => {

    html2canvas(document.querySelector("#capture")).then(canvas => {
      document.body.appendChild(canvas);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'mm', [246, 136]);
      pdf.addImage(imgData, 'PNG', 0, 0, 246, 136);
      pdf.save("download.pdf"); 
      document.body.removeChild(canvas);
   });
  }

  const months = ["Janeiro", "Fevereiro", "Março", "April", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

      api
      .post("/api/certified", { id: id }, config)
      .then((response) => {
        setData(response.data)
      })
    }, [ id ]);
  
  return (
    <Box>
        <CertifiedBorder id='capture'>
            <CertifiedContent>
              <Logos>
                <CertifiedLogoDiv style={{width: '7em'}}>
                  <CertifiedLogo src={Logo2} />
                </CertifiedLogoDiv>
                <CertifiedLogoDiv>
                  <CertifiedLogo src={Logo} />
                </CertifiedLogoDiv>
              </Logos>
              <CertifiedText> Certificamos que </CertifiedText>
              <CertifiedBigText> { data.name } </CertifiedBigText>
              <CertifiedText> concluiu com êxito o curso de </CertifiedText>
              <CertifiedBigText> { data.subject } </CertifiedBigText>
              <CertifiedText> em { new Date(data.date).getDay() } de { months[new Date(data.date).getMonth()] } de { new Date(data.date).getFullYear() }.</CertifiedText>
              
              <CertifiedSignatureDiv>
                <CertifiedSignature src={Assinatura} />
              </CertifiedSignatureDiv>
              <CertifiedText> Jefferson Borges </CertifiedText>
              <CertifiedText style={{fontWeight: 'bold', margin: '0'}}> Diretor da Escola da Palavra </CertifiedText>
            </CertifiedContent>
            <p style={{fontSize:'0.7em', marginTop: '2em'}}>app.escoladapalavra.com/certificado/6230c4c27c7aba08da85869e</p>
        </CertifiedBorder>
        <DownloadButton onClick={ () => { exportPdf() }}>Baixar Certificado</DownloadButton>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CertifiedBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3em;
  width: 1230px;
  height: 680px;
  background-image: url(${Background});
  background-size: cover;
  border: solid 1px black;
`;

const CertifiedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 80%;
  border-radius: 32px;
  background-color: white;
  margin-top: 2em;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 0.2em;
  margin-bottom: 2em;
`;

const CertifiedLogoDiv = styled.div`
  width: 10em;
  margin: 1em;
  align-items: flex-end;
`;

const CertifiedLogo = styled.img`
  width: 100%;
`;


const CertifiedSignatureDiv = styled.div`
  width: 12em;
  margin-top: 2em;
`;

const CertifiedSignature = styled.img`
  width: 100%;
`;

const CertifiedText = styled.p`
  width: 90%;
  text-align: center;
  font-size: 1em;
  margin: 0.2em;
  font-weight: 500;
  color: #19337e;
`;

const CertifiedBigText = styled.p`
  width: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 1.7em;
  margin: 0.2em;
  padding: 0.1em;
  background-color: #27367c;
  color: white;
  border-radius: 32px;
`;

const DownloadButton = styled.button`
  position: fixed;
  bottom: 1em;
  left: 50%;
  margin-left: -5em;
  background-color: white;
  width: 10em;
  height: 2em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 36px;
  box-shadow: 0em 0em 0.5em 0em rgba(0, 0, 0, 0.5);
  font-weight: 600;
  
  font-size: 1.5em;
`;

