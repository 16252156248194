import React from 'react';
import styled from "styled-components";

export default function Alert(props) {
  return (
    <AlertBox className={props.type}>
        <Message>{props.message}</Message>
    </AlertBox>
  );
}

const AlertBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid;
  border-radius: 6px;
  font-size: 0.8em;
  text-align: center;
  
  &.error {
    border-color: red;
    color: red;
  }
  
  &.success {
    border-color: #0075a4;
    color: #0075a4;
  }
`;

const Message = styled.p`
  margin: 0;
`;
