import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import api from "../../services/api";
import { useHistory } from "react-router-dom";

import BackButton from '../../components/micro/BackButton';

export default function AdminForum() {

    const [topics, setTopics] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [subject, setSubject] = useState('');
    
    let history = useHistory();

    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    useEffect(() => {
  
      api
        .post("/api/forum-admin", {}, config)
        .then((res) => {
          setTopics(res.data.topics);
          setSubjects(res.data.subjects);
        }) 
        .catch((err) => {
          // console.log(err);
        });
      }, [config]);
    
    const search = () => {
      api
        .post("/api/forum-admin", {title: title, description: description, subject: subject}, config)
        .then((res) => {
          setTopics(res.data.topics);
          setSubjects(res.data.subjects);
        }) 
        .catch((err) => {
          // console.log(err);
        });
      }

    
    return (
    <Box>
      <TopBox>
        <AlignLeftItemsDiv>
          <BackTopBox>
            <BackButton>Voltar</BackButton>
          </BackTopBox>
            <h2 style={{ textAlign: 'left', width: '90%' }}> Fórum </h2>
        </AlignLeftItemsDiv>
      </TopBox>
      <GradesBox>
        <GradeBox>
            <tr key='title'>
            <TopCell><InputText name="username" placeholder="MATÉRIA" onChange={ e => { setSubject(e.target.value) } } /></TopCell>
            <TopCell><InputText name="username" placeholder="TÍTULO" onChange={ e => { setTitle(e.target.value) } } /></TopCell>
            <TopCell><InputText name="username" placeholder="DESCRIÇÃO" onChange={ e => { setDescription(e.target.value) } } /></TopCell>
            <TopCell style={{width: '8vw'}}><Button onClick={() => {search()}}> FILTRAR</Button></TopCell>
            {/* <InputText name="username" placeholder='Nome de usuário' onChange={e => setUser(e.target.value)}/> */}
            </tr>
            {topics.map((topic) => {
            return (
                <tr key={topic._id}>
                <Cell> { subjects[topic.subject] } </Cell>
                <Cell> { topic.title } </Cell>
                <Cell> { topic.description } </Cell>
                <Cell onClick={() => { history.push("/admin/usuarios/" + topic._id) } }> Acessar </Cell>
                </tr>
            );
            })}
        </GradeBox>
      </GradesBox>
    </Box>
    );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 4%;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 86vw;
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10%;
    margin-right: 2%
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const GradesBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const GradeBox = styled.table`
  border-collapse: collapse;
  width: 86vw;
  padding: 0% 1.8% 0% 1.8%;
  margin-right: 2%;
  background-color: white;
  border: solid 1px #ADADAD;
`;

const TopCell = styled.td`
  border: solid 1px #ADADAD;
  padding: 0.5% 1% 0.5% 1%;
  margin: 0;
  font-weight: bold;
  color: #7D7D7D;
`;

const Cell = styled.td`
  border: solid 1px #ADADAD;
  padding: 0.5% 1% 0.5% 1%;
  margin: 0;
`;

const InputText = styled.input`
    font-size: 1em;
    width: 100%;

    border: none;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    color: #ADADAD;
    font-weight: bold;

    ::placeholder {
        color: #7D7D7D;
    }

    &:focus {
        outline: none;
        background-color: RGBA(255,255,255,0.1);
    }
`;

const Button = styled.button`
    border: none;
    background-color: RGBA(0,0,0,0);

    color: #7D7D7D;
    font-size: 1em;
    font-weight: bold;
`;
