import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import api from "../../services/api";
import { useHistory, Link } from "react-router-dom";

import BasicButton from '../../components/micro/BasicButton';

import EditSetting from '../../components/macro/EditSetting';

export default function AdminSettings() {

    const [settings, setSettings] = useState([]);

    const [edit, setEdit] = useState('');
    
    let history = useHistory();

    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    useEffect(() => {
  
      api
        .post("/api/open-settings", {}, config)
        .then((res) => {
          setSettings(res.data.settings);
        }) 
      }, [config]);

    const Back = () => {
      history.push("/admin");
    }  

    const Edit = (setting) => {
      setEdit(<EditSetting data={setting} />)
    }
    
    return (
    <Box>
      <TopBox>
        <AlignLeftItemsDiv>
          <BackTopBox onClick={() => { Back() }}>
            <BasicButton>Voltar</BasicButton>
          </BackTopBox>
            <h2 style={{ textAlign: 'left', width: '92%' }}> Configurações </h2>
        </AlignLeftItemsDiv>
        <div style={{ width: '12%', display: 'flex', alignItems: 'center'}}>
          <Link><BasicButton>Nova Matéria</BasicButton></Link>
        </div>
      </TopBox>
      <GradesBox>
        <GradeBox>
            <tr key='title'>
              <TopCell><InputText placeholder="NOME" /></TopCell>
              <TopCell><InputText placeholder="VALOR" value={''} /></TopCell>
              <TopCell style={{width: '8vw'}}><Button onClick={() => {  }}> FILTRAR</Button></TopCell>
            </tr>
            {settings.map((setting) => {
            return (
                <tr key={setting._id}>
                <Cell style={ { height: '3vw' } }> { setting.setting } </Cell>
                <Cell> { setting.value } </Cell>
                <Cell onClick={() => { Edit(setting) } }> Editar </Cell>
                </tr>
            );
            })}
        </GradeBox>
      </GradesBox>
      {edit}
    </Box>
    );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 4%;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 78vw;
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10%;
    margin-right: 2%
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const GradesBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const GradeBox = styled.table`
  border-collapse: collapse;
  width: 86vw;
  padding: 0% 1.8% 0% 1.8%;
  margin-right: 2%;
  background-color: white;
  border: solid 1px #ADADAD;
`;

const TopCell = styled.td`
  border: solid 1px #ADADAD;
  padding: 0.5% 1% 0.5% 1%;
  margin: 0;
  font-weight: bold;
  color: #7D7D7D;
`;

const Cell = styled.td`
  border: solid 1px #ADADAD;
  padding: 0.5% 1% 0.5% 1%;
  margin: 0;
`;

const InputText = styled.input`
    font-size: 1em;
    width: 100%;

    border: none;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    color: #ADADAD;
    font-weight: bold;

    ::placeholder {
        color: #7D7D7D;
    }

    &:focus {
        outline: none;
        background-color: RGBA(255,255,255,0.1);
    }
`;

const Button = styled.button`
    border: none;
    background-color: RGBA(0,0,0,0);

    color: #7D7D7D;
    font-size: 1em;
    font-weight: bold;
`;
