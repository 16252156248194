import React, { useState } from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import api from "../../../services/api";

import BasicButton from '../../micro/BasicButton';

export default function Unsubscribe(props) {
  
  const [description, setDescription] = useState('');

  let history = useHistory();

    const config = {
        headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
        }
    }

    const Cancel = () => {
      history.push("/");
    }

    const SendCancel = () => {
        if (description !== '') {
            api
            .post("/api/unsubscribe",{ subject: props.data, description: description }, config)
            .then((response) => {
              history.push("/");
            }) 
        }
    }
  
  return (
    <SubjectMainBox>
        <SubjectBox>
            <SubTitle>Qual o motivo do cancelamento da inscrição?</SubTitle>
            <InputDescription onChange={e => setDescription(e.target.value)} placeholder='* Campo obrigatório' />
            <ButtonBox>
                <LinkButton onClick={() => { Cancel() }}><BasicButton>Fechar</BasicButton></LinkButton>
                <LinkButton onClick={() => { SendCancel() }}><BasicButton>Enviar o motivo</BasicButton></LinkButton>
            </ButtonBox>
        </SubjectBox>
      </SubjectMainBox>
  );
}   



const SubjectMainBox = styled.div`
  background-color: RGBA(0,0,0,0.5);    
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const SubjectBox = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  padding: 1%;
  border-radius: 16px;
`;

const InputTitle = styled.input`
  border: solid 1px black;
  border-radius: 32px;
  width: 40vw;
  height: 2vh;
  padding: 1vw 1.5vw 1vw 1.5vw;
  &:focus{
    outline: none;
  }
`;

const InputDescription = styled.textarea`
  border: solid 1px black;
  border-radius: 32px;
  width: 40vw;
  height: 25vh;
  padding: 1.5vw;
  &:focus{
    outline: none;
  }
`;

const SubTitle = styled.h3`
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 50vw;
  margin-top: 2%;
  margin-bottom: 2%;
`;

const LinkButton = styled.div`
  margin-left: 1vw;
  margin-right: 1vw;
`;