import React from 'react';
import styled from "styled-components";

export default function BasicButton({children}) {
  return (
    <Ok>{children}</Ok>
  );
}   

const Ok = styled.button`
  width: 105%;
  height: 2em; 
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 32px 32px 32px 32px;
  background-color: RGBA(255,255,255,1);
  font-size: 1em;
  padding-top: 1em;
  padding-bottom: 2em;
  margin: 1%;
  margin-top: 3%;
  color: black;
  &:hover {
    background-color: RGBA(240,240,240,1);;
    color: RGBA(0,0,0,1);
  }
  cursor: pointer;
`;

  