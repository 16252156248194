import React, { useState } from 'react';
import styled from "styled-components";

import { useHistory, useLocation } from "react-router-dom";

import Alert from "../../components/macro/Alert";

import BgImage from './../../images/bg-bw2.jpg';
import LogoImage from './../../images/SENIB.png';

import api from "../../services/api";

export default function UpdatePasswordByToken() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);

    let history = useHistory();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const submitValue = () => {
        if (password && confirmPassword) {
            if (password === confirmPassword) {
                api
                    .post("/api/auth/update-password-by-token", {
                        userId: queryParams.get('userId'),
                        token: queryParams.get('token'),
                        newPassword: password + '1'
                    })
                    .then(() => {
                        setAlertMessage({
                            type: 'success',
                            message: 'Senha alterada com sucesso. Agora faça o login utilizando a nova senha.'
                        });
                    })
                    .catch((err) => {
                        if (err.response) {
                            setAlertMessage({type: 'error', message: err.response.data.message});
                        } else {
                            setAlertMessage({
                                type: 'error',
                                message: 'Um erro inesperado aconteceu. Por favor tente novamente mais tarde.'
                            });
                        }
                    });
            } else {
                setAlertMessage({ type: 'error', message: 'As senhas não correspondem.' });
            }
        } else {
            setAlertMessage({ type: 'error', message: 'Preencha os campos com sua nova senha.' });
        }
    }
    
    return (
      <MainBox>
        <LogoBox>
            <Logo src={LogoImage}/>
        </LogoBox>
        <FormBox>
            <TitleFirst>Alterar minha senha de acesso</TitleFirst>

            <div style={{ display: (alertMessage && alertMessage.type === 'success') ? 'none' : 'block' }}>
                <InputText type="password" name="password"  placeholder='Senha' onChange={e => setPassword(e.target.value)}/>
                <InputText type="password" name="confirmar-password"  placeholder='Confirmar senha' onChange={e => setConfirmPassword(e.target.value)}/>

                <Button onClick={submitValue}>Alterar</Button>
            </div>

            { alertMessage && <Alert type={alertMessage.type} message={alertMessage.message} /> }

            <BottomBox>
                <BottomText onClick={() => { history.push("/entrar") }}>Entre em sua conta</BottomText>
            </BottomBox>
        </FormBox>
      </MainBox>
    );
}

const MainBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #141414;
    
    justify-content: center;
    align-items: center;
    background-image: url(${BgImage});
    background-size: cover;
    z-index: 11;
`;

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    margin: 0;
    padding: 0;
    width: 20%;
    margin-bottom: 5%;

    @media(max-width: 1000px) {
        width: 50%;
        margin-bottom: 1.5em;
    }
`;

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 40em;
    padding: 2%;

    background: #ffffff;
    
    border-radius: 32px;

    @media(max-width: 1000px) {
        width: 20em;
        height: 24em;
    }
`;

const InputText = styled.input`
    margin-bottom: 10px;
    padding: 1.5%;
    font-size: 1em;

    border: 1px solid #0075a4;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    
    color: #0075a4;
    
    margin: 1% 3% 1% 3%;

    width: 96%;

    ::placeholder {
        color: #0075a4;
    }
    &:focus {
        outline: none;
        border: 2px solid #0075a4;
    }

    @media(max-width: 1000px) {
        width: 90%;
        font-size: 0.9em;
    }
`;

const Button = styled.button`
    padding: 2%;
    margin: 1% 3% 1% 3%;

    width: 100%;

    border: 1px solid #0075a4;
    border-radius: 32px;
    background-color: white;

    color: #0075a4;
    font-size: 1.2em;
    font-weight: bold;

    cursor: pointer;

    ::placeholder {
        color: white;
    }
    
    @media(max-width: 1000px) {
        width: 95%;
        font-size: 0.9em;
    }
`;

const BottomBox = styled.div`
    text-align: center;
    width: 100%;
`;

const BottomText = styled.h1`
    color: #0075a4;
    font-size: 1em;
    padding: 0;
    margin: 30px 0.3em 0.3em 0.3em;
    cursor: pointer;
    @media(max-width: 1000px) {
        font-size: 0.9em;
    }
`;

const TitleFirst = styled.h1`
    color: #0075a4;
    font-size: 1.7em;
    margin-bottom: 1.3em;
    text-align: center;
`;
