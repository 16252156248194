import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import api from "../../services/api";

import BackButton from '../../components/micro/BackButton';

import {
  useHistory,
  useParams
} from "react-router-dom";


export default function Grades() {
    
  let { id, token } = useParams();
  let history = useHistory();

  const [grades, setGrades] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
      .get("/api/grades", config)
      .then((res) => {
        setGrades(res.data.grades);
      }) 
      .catch((err) => {
        // console.log(err);
      });
    }, [history, token, id]);

    const Back = () => {
      history.push("/");
    }  

    const Access = (subjectId) => {
      history.push("/resultado/" + subjectId);
    }

  return (
    <Box>
      <SubBox>
        <GradesBox>
          <GradeBox>
            <tr key='title'>
              <TopCell>MATÉRIA</TopCell> <TopCell>PROFESSOR</TopCell> <TopCell style={{width: '7em'}}>RODADA</TopCell><TopCell style={{width: '8em'}}>RESULTADO</TopCell>
            </tr>
          {grades.map((grade) => {
            return (
              <tr key={grade._id} onClick={() => {Access(grade.subject)}}>
                <Cell style={{height: '3vw'}}>{grade.name}</Cell> <Cell>{grade.teacher}</Cell> <Cell>4/2021</Cell> <Cell>Acessar</Cell>
              </tr>
            );
          })}
          </GradeBox>
        </GradesBox>
        <BackTopBox onClick={ () => {Back()}}>
          <BackButton>Voltar</BackButton>
        </BackTopBox>
      </SubBox>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 4%;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
  @media(max-width: 1000px) {
    padding-top: 2em;
  }
`;

const SubBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 2em;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 32px;
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 86vw;
    @media(max-width: 1000px) {
      width: 90vw;
      margin: 0;
      padding: 0;
    }
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 15%;
    margin-top: 2%;
    @media(max-width: 1000px) {
      width: 20%;
    }
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const GradesBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const GradeBox = styled.table`
  border-collapse: collapse;
  width: 86vw;
  padding: 0% 1.8% 0% 1.8%;
  margin-right: 2%;
  background-color: white;
  border: solid 1px #ADADAD;
`;

const TopCell = styled.td`
  border: solid 1px #ADADAD;
  padding: 0.5% 1% 0.5% 1%;
  margin: 0;
  font-weight: bold;
  color: #7D7D7D;
  @media(max-width: 1000px) {
    font-size: 0.6em;
  }
`;

const Cell = styled.td`
  border: solid 1px #ADADAD;
  padding: 0.5% 1% 0.5% 1%;
  margin: 0;
  @media(max-width: 1000px) {
    font-size: 0.8em;
  }
`;
