import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import api from "../../services/api";

import BasicButton from '../../components/micro/BasicButton';
import NewTopic from '../../components/macro/NewTopic';
import TopicReplies from '../../components/macro/TopicReplies';
import TopicReply from '../../components/macro/TopicReply';

import LoadingScreen from 'react-loading-screen';

import { useMediaQuery } from 'react-responsive';

import {
  useHistory,
  useParams,
  Link
} from "react-router-dom";


export default function Forum() {
    
  let { id } = useParams();
  let history    = useHistory();

  const [open, setOpen] = useState();
  const [data, setData] = useState({topics: [], user: { name: '', profile: '' } });
  const [loading, setLoading] = useState(true);
  
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
      .post("/api/search-topics", { subject: id }, config)
      .then((res) => {
        setData(res.data);
        if (res.data.topics.length !== res.data.len) {
          history.go(0)
        } else {
          setLoading(false)
        }
        
      })
    }, [history, id]);
  
  const OpenNew = () => {
    setOpen(<NewTopic data={{id : id}}/>);
  }

  const OpenTopic = (topic, data) => {
    setOpen(<TopicReplies topic={{topic: topic, data: data}} />);
  }

  const OpenReply = (topic, data) => {
    setOpen(<TopicReply topic={{topic: topic, data: data}} />);
  }

  const Back = () => {
    history.push("/curso/" + id );
  }
  
  
  return (
    <LoadingScreen
            loading={loading}
            bgColor='#f1f1f1'
            spinnerColor='#000000'
            textColor='#676767'
            text=''
    > 
    <Box>
      <SubBox>
          <TopBox>
              <Link style={ isTabletOrMobile ? {width: '30%', marginRight: '0.5em'} : { width: '15%', margin: '1em'}} onClick={() => {Back()}}><BasicButton>Voltar</BasicButton></Link>
              <Link style={ isTabletOrMobile ? {width: '60%', marginLeft: '0.5em'} : { width: '15%', margin: '1em'}} onClick={() => {OpenNew()}}><BasicButton>Faça sua pergunta</BasicButton></Link>
          </TopBox>  
          {data['topics'].map((topic, index) => {
              return (
                <Link  style={{textDecoration: 'none', color: 'black'}}>
                  <TopicBox >
                      <Profile src={ topic !== null ? topic.user.profile : '' } />
                      <TopicInfo>
                        <div style={{width: '85%'}} onClick={() => {OpenTopic(topic, data)}}>
                          <TopicTitle>{ topic !== null ? topic.topic.title : '' }</TopicTitle>
                          <TopicSubTitle>{ topic !== null ? topic.user.name : '' } - { topic !== null ? new Date(topic.topic.createdAt).toLocaleString("pt-BR") : '' }</TopicSubTitle>
                        </div>
                        {
                          data.teacher === true ?
                          <Reply onClick={ () => {OpenReply(topic, data)} }>
                            <BasicButton>Responder Aluno</BasicButton>
                          </Reply>
                          :
                          ''
                        }
                      </TopicInfo>
                  </TopicBox>
                </Link>
              );
          })}
        </SubBox>
      {open}
    </Box>
    </LoadingScreen >
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 5em;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    padding-top: 4em;
  }
`;

const SubBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 16px;
  padding: 2em;
  padding-top: 1em;
  width: 72em;
  @media (max-width: 1000px) {
    width: 18em;
  }
`;

const TopicBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 80em;
  align-items: center;
  padding: 1em;
  margin: 0.2em;
  border: solid 1px RGBA(0,0,0,0.2);
  border-radius: 16px;
  @media(max-width: 1000px) {
    padding-top: 1em;
    padding-bottom: 1em;
    flex-direction: column;
    width: 90vw;
    height: auto;
  }
`;

const TopBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1.5vw;
  @media(max-width: 1000px) {
    width: 100%; 
    margin-right: 1em;
  }
`;

const Profile = styled.img`
  width: 5vw;
  height: 5vw;
  border-radius: 100px;
  @media(max-width: 1000px) {
    width: 20vw;
    height: 20vw;
  }
`;

const TopicInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-left: 2%;
  width: 90%;
  @media(max-width: 1000px) {
    flex-direction: column;
  }
`;

const TopicTitle = styled.h3`
    margin: 0;
    padding: 0;
    @media(max-width: 1000px) {
      margin-top: 1em;
    }
`;

const TopicSubTitle = styled.p`
    margin: 0;
    margin-top: 1%;
    padding: 0;
    font-size: 0.9em;
`;

const Reply = styled.p`
  width: 15%;
  @media(max-width: 1000px) {
    width: 70%;
  }
`;