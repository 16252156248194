import React, { useState } from 'react';
import styled from "styled-components";
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import RecoverPasswordRequest from './pages/RecoverPassword';
import UpdatePasswordByToken from './pages/UpdatePasswordByToken';
import Account from './pages/Account';
import Subject from './pages/Subject';
import Forum from './pages/Forum';
import Lesson from './pages/Lesson';
import Test from './pages/Test';
import TestHome from './pages/TestHome';
import Result from './pages/Result';
import Grades from './pages/Grades';
import Admin from './pages/Admin';
import AdminSubject from './pages/AdminSubject';
import AdminUsers from './pages/AdminUsers';
import AdminForum from './pages/AdminForum';
import AdminSettings from './pages/AdminSettings';
import SubjectStudio from './pages/SubjectStudio';
import UserStudio from './pages/UserStudio';
import NewSubject from './pages/NewSubject';
import FirstTime from './pages/FirstTime';
import Help from './pages/Help';
import Welcome from './pages/Welcome';
import HowToUse from './pages/HowToUse';
import Certified from './pages/Certified';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  const [open, setOpen] = useState(false);

  const Quit = () => {
    sessionStorage.clear();
  }
  return (
    
      <Router>
        { open ? <div style={{width: '100vw', height: '100vh', position: 'fixed', zIndex: '9'}} onClick={ () => { setOpen(false)} }></div> : '' }
        <Box>
          <HomeBar>
            <Menu>
              <Hamburger onClick={() => setOpen(!open)}>_<br />_<br />_</Hamburger>
            </Menu>
          </HomeBar>
          <DropdownMenu open={open}>
            <Items>
              <Item><Link to="/" style={{textDecoration: 'none', color: 'black', fontWeight: 'bold'}}>Inicial</Link></Item>
              <Item><Link to="/notas" style={{textDecoration: 'none', color: 'black'}}>Notas</Link></Item>
              <Item><Link to="/conta" style={{textDecoration: 'none', color: 'black'}}>Minha conta</Link></Item>
              <Item><Link to="/inicial" onClick={ () => Quit() } style={{textDecoration: 'none', color: 'black'}}>Sair</Link></Item>
            </Items>
          </DropdownMenu>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/inicial" component={FirstTime} />
          <Route exact path="/entrar" component={Login}/>
          <Route exact path="/cadastrar" component={Register} />
          <Route exact path="/recuperar-senha" component={RecoverPasswordRequest} />
          <Route exact path="/redefinir-senha" component={UpdatePasswordByToken} />
          <Route exact path="/notas" component={Grades} />
          <Route exact path="/conta" component={Account} />
          <Route exact path="/avaliacao/:id" component={Test} />
          <Route exact path="/iniciar-avaliacao/:id" component={TestHome} />
          <Route exact path="/resultado/:id" component={Result} />
          <Route exact path="/curso/:id" component={Subject} />
          <Route exact path="/forum/:id" component={Forum} />
          <Route exact path="/curso/:id/:lesson" component={Lesson} />
          <Route exact path="/arquivo/:id/:token" component={File} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/admin/cursos" component={AdminSubject} />
          <Route exact path="/admin/cursos/:id" component={SubjectStudio} />
          <Route exact path="/admin/nova-curso" component={NewSubject} />
          <Route exact path="/admin/usuarios" component={AdminUsers} />
          <Route exact path="/admin/usuarios/:id" component={UserStudio} />
          <Route exact path="/admin/forum" component={AdminForum} />
          <Route exact path="/admin/ajustes" component={AdminSettings} />
          <Route exact path="/bem-vindo" component={Welcome} />
          <Route exact path="/como-usar" component={HowToUse} />
          <Route exact path="/certificado/:id" component={Certified} />
        </Switch>
      </Box>
    </Router>
    
  );
}

const Box = styled.div`
  -webkit-touch-callout: none;  /* iPhone OS, Safari */
  -webkit-user-select: none;    /* Chrome, Safari 3 */
  -html-user-select: none;     /* Safari 2 */
  -moz-user-select: none;       /* Firefox */
  -ms-user-select: none;        /* IE10+ */
  user-select: none;            /* Possível implementação no futuro */
  width: 100%;
`;

const HomeBar = styled.div`
  position: fixed;
  width: 100%;
  height: 2.2em;
  overflow-y: auto;
  background-color: white;
  overflow: hidden;
  -webkit-overflow-scrolling:touch;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  z-index: 10;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
`;

const Hamburger = styled.p`
  line-height: 0.2em;
  font-size: 2em;
  margin-top: -0.2%;
  margin-left: 0.3em;
  padding: 0;
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: fixed;
  top: 2.2em;
  overflow-y: auto;
  background-color: white;
  overflow: hidden;
  -webkit-overflow-scrolling:touch;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.1);
  padding-left: 1em;
  padding-right: 3em;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  z-index: 10;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0 0.5em 0;
`;

const Item = styled.div`
  margin: 0.2em;
  font-size: 1em;
  @media(max-width: 1000px) {
    font-size: 1.2em;
  }
`;

export default App;
