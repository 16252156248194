import React, { useState } from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import api from "../../../services/api";

import BasicButton from '../../micro/BasicButton';

export default function TopicReplies(topic, data) {
  
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  let history = useHistory();

    const config = {
        headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
        }
    }

  const Cancel = () => {
      history.go(0);
  }
  
  return (
    <SubjectMainBox>
        <SubjectBox>
            <TopicBox >
                <TopicInfo>
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                      <Profile src={topic.topic.topic.user.profile} />
                      <h4 style={{margin: '0%', marginTop: '0.8em'}}>{topic.topic.topic.user.name}</h4>
                      <ReplyTitle>{new Date(topic.topic.topic.topic.createdAt).toLocaleString("pt-BR")}</ReplyTitle>
                    </div>
                    <TopicTitle>{topic.topic.topic.topic.title}</TopicTitle>
                    <TopicSubTitle>{topic.topic.topic.topic.description}</TopicSubTitle>
                  </TopicInfo>
                    <h4 style={{margin: '1em', marginTop: '0'}}>Respostas</h4>
                    {topic.topic.topic.topic.answers.map((answer, index) => {
                    return (
                      <TopicInfo>
                        <TopicSubTitle>{answer.answer}</TopicSubTitle>
                      </TopicInfo>
                        );
                    })}
                <LinkButton onClick={() => { Cancel() }}><BasicButton>Fechar</BasicButton></LinkButton>
            </TopicBox>
        </SubjectBox>
      </SubjectMainBox>
  );
}   



const SubjectMainBox = styled.div`
  background-color: RGBA(0,0,0,0.5);    
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const SubjectBox = styled.div`
  background-color: #E9E9E9;
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

const TopicBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2em;
    @media(max-width: 1000px) {
      padding: 1em;
    }
`;

const Profile = styled.img`
    width: 7em;
    height: 7em;
    border-radius: 100px;
`;

const TopicInfo = styled.div`
  align-items: center;
  justify-content: center;
  text-align: left;
  background-color: white;
  width: 40em;
  padding: 1.5em;
  margin-bottom: 0.8em;
  border-radius: 16px;
  @media(max-width: 1000px) {
    width: 16em;
  }
`;

const TopicTitle = styled.h3`
    margin: 0;
    padding: 0;
    padding-top: 1%;
`;

const ReplyTitle = styled.p`
    margin: 0;
    padding: 0;
    margin-top: 0.4em;
    margin-bottom: 1em;
    font-size: 0.9em;
`;

const TopicSubTitle = styled.p`
    margin: 0;
    margin-top: 2%;
    padding: 0;
    padding-bottom: 2%;
    font-size: 0.9em;
`;

const LinkButton = styled.div`
  margin-top: 2em;
`;