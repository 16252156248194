import React, {useEffect} from 'react';
import styled from "styled-components";

import api from "../../services/api";
import { useHistory } from "react-router-dom";

import { VscOutput, VscAccount, VscCommentDiscussion, VscSettings } from "react-icons/vsc";

export default function Admin() {

  let history = useHistory();

  useEffect(() => {
    
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }

    api
      .get("/api/test/admin", config)
      .then((res) => {
        // console.log('Admin ok')
      }) 
      .catch((err) => {
        history.push("/");
        // console.log(err);
      });
    }, []);
  
  return (
    <Box>
      <OptionsBox>
        <SubOptionsBox onClick={() => { history.push( '/admin/cursos') }}>
            <VscOutput style={{width: '6em', height: '6em', marginBottom: '2em', marginTop: '1em'}} />
            <h3 style={{textAlign: 'center', fontSize: '1em'}}>Matérias</h3>
        </SubOptionsBox>
      </OptionsBox>
      <OptionsBox>
        <SubOptionsBox onClick={() => { history.push( '/admin/usuarios') }}>
            <VscAccount style={{width: '6em', height: '6em', marginBottom: '2em', marginTop: '1em'}} />
            <h3 style={{textAlign: 'center', fontSize: '1em'}}>Usuários</h3>
        </SubOptionsBox>
      </OptionsBox>
      <OptionsBox>
        <SubOptionsBox onClick={() => { history.push( '/admin/forum') }}>
            <VscCommentDiscussion style={{width: '6em', height: '6em', marginBottom: '2em', marginTop: '1em'}} />
            <h3 style={{textAlign: 'center', fontSize: '1em'}}>Fórum</h3>
        </SubOptionsBox>
      </OptionsBox>
      <OptionsBox>
        <SubOptionsBox onClick={() => { history.push( '/admin/ajustes') }}>
            <VscSettings style={{width: '6em', height: '6em', marginBottom: '2em', marginTop: '1em'}} />
            <h3 style={{textAlign: 'center', fontSize: '1em'}}>Ajustes</h3>
        </SubOptionsBox>
      </OptionsBox>
    </Box>
  );
}   

const Box = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OptionsBox = styled.div`
  margin: 1em;
`;

const SubOptionsBox = styled.div`
  width: 13em;
  height: 18em;
  border: solid 1px black;
  background-color: white;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;