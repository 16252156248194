import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import api from "../../services/api";

import BasicButton from '../../components/micro/BasicButton';
import BackButton from '../../components/micro/BackButton';

import Countdown from 'react-countdown';

import {
  useHistory,
  useParams
} from "react-router-dom";


export default function Test() {
    
  let { id, lesson } = useParams();
  let history = useHistory();

  const [test, setTest] = useState([]);
  const [answers, setAnswer] = useState([]);
  const [countdown, setCountdown] = useState(16000000);

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }
    api
      .post("/api/open-test", { id: id }, config)
      .then((res) => {
        setTest(res.data.test);
        setCountdown(Date.parse(res.data.time) + 3600000 - Date.now())
      }) 
      .catch((err) => {
        // console.log(err);
        // history.push("/");
      });

    }, [history, id, lesson]);

    const sendQuestion = (index, answer) => {
      answers[index] = answer
    }

    const sendTest = () => {
      const config = {
        headers: {
          'Content-Type':'application/json',
          'x-access-token': sessionStorage.getItem('session'),
          'Access-Control-Allow-Origin': '*',
        }
      }
      api
        .post("/api/solve-test", { id: id, answers: answers }, config)
        .then((res) => {
          // console.log(res.data.message)
          if (res.data.message == 'Teste finalizado!'){
            history.push("/resultado/" + id );
          }
        }) 
        .catch((err) => {
          // console.log(err);
        });
    }

    const Back = () => {
      history.push("/iniciar-avaliacao/" + id );
    }
    
  return (
    <Box>
      <TopBox>
        <AlignLeftItemsDiv>
          <BackTopBox onClick={() => {Back()} }>
            <BackButton>Voltar</BackButton>
          </BackTopBox>
          <h2 style={{ textAlign: 'left', width: '90%' }}> Avaliação </h2>
        </AlignLeftItemsDiv>
      </TopBox>
      <TestBox>
      {test.map((question, index) => {
        if (question.question.split("\n").length > 1) {
            return (
                <QuestionBox key={index}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><h4>{index + 1 + '. '}{question.question.split("\n")[0]}</h4><p style={{margin: '0px 0px 0px 0.7%'}}> [{question.weight}]</p></div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    {question.question.split("\n").map((sub, index) => {
                        return ((index>0) ? (<span>{sub}</span>): '');
                    })}
                    </div>
                    <AlternativesBox>
                    {Object.keys(question['alternatives']).map((alternative, idx) => {
                        return (
                          <AlternativeBox><input type='radio' name={index} value={alternative} id={index + alternative} onChange={ () => sendQuestion(index, alternative) } /><AlternativeLabel htmlFor={index + alternative}>{question['alternatives'][alternative]}</AlternativeLabel></AlternativeBox>
                        );
                    })}
                    </AlternativesBox>
                </QuestionBox>
            );
        } else {
            return (
                <QuestionBox key={index}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><h4>{index + 1 + '. '}{question.question}</h4><p style={{margin: '0px 0px 0px 0.7%'}}> [{question.weight}]</p></div>
                    <AlternativesBox>
                    {Object.keys(question['alternatives']).map((alternative, idx) => {
                        return (
                          <AlternativeBox><input type='radio' name={index} value={alternative} id={index + alternative} onChange={ () => sendQuestion(index, alternative) } /><AlternativeLabel htmlFor={index + alternative}>{question['alternatives'][alternative]}</AlternativeLabel></AlternativeBox>
                        );
                    })}
                    </AlternativesBox>
                </QuestionBox>
            );
        }
//          let minhaVar = question.question.split("\n").length;
//                console.log(minhaVar);
//                for (var i = 0; i < minhaVar.length; i++) {
//                    console.log("minha var("+i+"): "+ minhaVar[i]);
//                }
//        if (question.question.match("\n") !== null) {
//            let minhaVar = question.question.match("\n").length;
//            console.log(index + ' ' + question.question + '-->'+minhaVar);
//        }
        })}
        <PreButtonBox>
            <ButtonBox>
                <div style={{margin: "2%", width: '20vw'}} onClick={() => {Back()}}><BasicButton>Voltar</BasicButton></div>
                <div style={{margin: "2%", width: '20vw'}} onClick={() => {sendTest()}}><BasicButton>Finalizar</BasicButton></div>
            </ButtonBox>
      </PreButtonBox>
      </TestBox>
      <Clock>
        <Countdown
        date={ Date.now() + countdown }
        renderer={props => <div>{ props.minutes.toString().padStart(2, '0') + ':' + props.seconds.toString().padStart(2, '0')}</div>}
        />
      </Clock>
    </Box>
  );
}   
const Box = styled.div`

  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-top: 4%;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
  
`;

const AlignLeftItemsDiv = styled.div`
    display: flex;
    width: 82vw;
    @media(max-width: 1000px) {
      font-size: 0.9em;
      margin-top: 1em;
    }
`;

const BackTopBox = styled.div`
    display: flex;
    align-self: center;
    width: 10%;
    margin-right: 2%;
    @media(max-width: 1000px) {
      width: 25%; 
    }
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5%;
`;

const TestBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0vw;
  width: 82vw;
`;

const QuestionBox = styled.div`
    background-color: white;
    padding: 2% 4% 2% 4%;
    margin-bottom: 1em;
`;

const AlternativesBox = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const AlternativeBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 2em;
  margin-bottom: 1em;
`;

const AlternativeLabel = styled.label`
  margin-left: 0.5em;
`;

const PreButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 1%;
`;

const Clock = styled.div`
  position: fixed;
  right: 3em;
  bottom: 3em;
  background-color: white;
  width: 5em;
  height: 5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5em;
  box-shadow: 0em 0em 0.5em 0em rgba(0, 0, 0, 0.5);
  font-weight: 600;
`;