import React, {useState} from 'react';
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import api from "../../../services/api";

export default function EditSetting(props) {
  const [cancel, setCancel] = useState(false);
  const [value, setValue] = useState('');
  
  let history = useHistory();

  console.log(props)

  const updateSetting = () => {
    const config = {
      headers: {
        'Content-Type':'application/json',
        'x-access-token': sessionStorage.getItem('session'),
      }
    }
        api
        .post("/api/edit-setting/",{
            id: props.data._id,
            value: value
        }, config)
        .then(() => {
            history.go(0);
        })  
  }

  return (
    <SubjectMainBox>
        <SubjectBox>
            <TextSubjectBox>
                <TitleSubject>{props.data.setting}</TitleSubject>
                <InputText placeholder={props.data.value} onChange={ e => { setValue(e.target.value) } } />
                <ButtonBox>
                  <SummaryButton onClick={() => { setCancel(true) }}>Fechar</SummaryButton>
                  <SummaryButton onClick={() => { updateSetting() }}>Atualizar</SummaryButton>
                </ButtonBox>
            </TextSubjectBox>
        </SubjectBox>
      </SubjectMainBox>
  );
}   



const SubjectMainBox = styled.div`
  background-color: RGBA(0,0,0,0.5);    
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const SubjectBox = styled.div`
  display: flex;
  flex-direction: row;  
`;

const TextSubjectBox = styled.div`
  display: flex;
  flex-direction: column;    
  align-items: center;
  justify-content: center;
  padding: 1em 1.5em 1em 1.5em;
  background-color: white;
  border-radius: 32px;
`;

const TitleSubject = styled.h2`
  text-align: center;
  padding: 0 0 0 0;
  font-size: 1.2em;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;    
  align-items: center;
  justify-content: center;
`;

const SummaryButton = styled.button`
  padding: 0.5em;
  margin: 1em 0.5em 0 0.5em;

  border: 1px solid black;
  border-radius: 32px;
  background-color: white;

  color: black;
  font-size: 1em;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    border: 1px solid black;
    color: white;
    background-color: black;
  }

  @media(max-width: 1000px) {
    font-size: 0.7em;
    margin: 1em 0.4em 0 0.4em;
  }
`;

const InputText = styled.input`
    font-size: 1em;
    padding: 0.5em;
    border: solid 1px black;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    color: black;
    font-weight: bold;

    ::placeholder {
        color: #7D7D7D;
    }

    &:focus {
        outline: none;
        background-color: RGBA(255,255,255,0.1);
    }
`;
