import React, {useState, useEffect} from 'react';
import ReactPixel from 'react-facebook-pixel';
import styled from "styled-components";

import InputMask from "react-input-mask";

import { useHistory } from "react-router-dom";

import BgImage from './../../images/bg-bw2.jpg';
import LogoImage from './../../images/SENIB.png';
import Error from '../../components/macro/Error/index';

import api from "../../services/api";

import { useMediaQuery } from 'react-responsive';

export default function Register() {
    const [name, setName] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState('');

    let history = useHistory();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })

    const submitValue = () => {
        if ( name !== '' && email !== '' && password !== '' && confirmPassword !== '' ) {
            if (password === confirmPassword){
                api
                .post("/api/auth/signup",{
                    name: name,
                    password: password + '1',
                    email: email.toLowerCase(),
                    phone: telefone,
                    roles: ['user']
                })
                .then((response) => {
                    ReactPixel.track('CompleteRegistration');

                    api
                    .post("/api/auth/signin",{
                        email: email,
                        password: password + '1'
                    })
                    .then((response) => {
                        sessionStorage.setItem('session', response.data.accessToken);
                        history.push("/");
                    });
                })
                .catch((err) => {
                    if (err.response.status == 400){
                        setErrorMessage('O email já está em uso');
                    }
                });
        } else {
            setErrorMessage('As senhas não correspondem');
        }
    } else {
        setErrorMessage('Preencha todos os campos');
    }
    }

    useEffect(() => {
        if (errorMessage !== '') {
            setError(Error(errorMessage));
        }
    }, [errorMessage]);

    const styleBtnW = isTabletOrMobile ? '89%' : '96%'
    const styleBtnF = isTabletOrMobile ? '0.88em' : '1em'
    const styleBtnP = isTabletOrMobile ? '0.4em' : '0.5em'

    return (
      <MainBox>
        <LogoBox>
            <Logo src={LogoImage}/>
        </LogoBox>
        <FormBox>
            <TitleFirst>Crie uma conta</TitleFirst>
            <InputText type="name" name="name" placeholder='Nome completo' onChange={e => setName(e.target.value)}/>
            <InputMask id='phone' type="telefone" name="telefone" id="telefone" placeholder='Telefone' mask="(99) 99999-9999" onChange={e => setTelefone(e.target.value)} style={{ marginBottom: '1%', padding: styleBtnP, fontSize: styleBtnF, border: '1px solid #0075a4', borderRadius: '32px', backgroundColor: 'RGBA(0,0,0,0)', outline: 'none', color: '#0075a4', width: styleBtnW, marginTop: '1%'}}/>
            <InputText type="email" name="email"  placeholder='Email' onChange={e => setEmail(e.target.value.toLowerCase() )}/>
            <InputText type="password" name="password"  placeholder='Senha' onChange={e => setPassword(e.target.value)}/>
            <InputText type="password" name="confirmar-password"  placeholder='Confirmar senha' onChange={e => setConfirmPassword(e.target.value)}/>

            <Button onClick={submitValue}> Cadastrar</Button>
            <div style={{textAlign: 'center', width: '100%', margin: '0.7em'}}>{ error }</div>
            <BottomBox>
                <BottomText onClick={() => { history.push("/entrar") }}> Entre em sua conta </BottomText>
            </BottomBox>
        </FormBox>
      </MainBox>
    );
}

const MainBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #141414;
    
    justify-content: center;
    align-items: center;
    background-image: url(${BgImage});
    background-size: cover;
    z-index: 11;
`;

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    margin: 0;
    padding: 0;
    width: 20%;
    margin-bottom: 5%;

    @media(max-width: 1000px) {
        width: 50%;
        margin-bottom: 1.5em;
    }
`;

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 40em;
    height: 28em;
    padding: 2%;

    background: #ffffff;
    
    border-radius: 32px;

    @media(max-width: 1000px) {
        width: 20em;
        height: 24em;
    }
`;

const Title = styled.h1`
    color: white;
    font-size: 2.6em;
    text-align: center;
`

const InputText = styled.input`
    margin-bottom: 10px;
    padding: 1.5%;
    font-size: 1em;

    border: 1px solid #0075a4;
    border-radius: 32px;
    background-color: RGBA(0,0,0,0);
    
    color: #0075a4;
    
    margin: 1% 3% 1% 3%;

    width: 96%;

    ::placeholder {
        color: #0075a4;
    }
    &:focus {
        outline: none;
        border: 2px solid #0075a4;
    }

    @media(max-width: 1000px) {
        width: 90%;
        font-size: 0.9em;
    }
`;

const Button = styled.button`
    padding: 2%;
    margin: 1% 3% 1% 3%;

    width: 100%;

    border: 1px solid #0075a4;
    border-radius: 32px;
    background-color: white;

    color: #0075a4;
    font-size: 1.2em;
    font-weight: bold;

    cursor: pointer;

    ::placeholder {
        color: white;
    }
    
    @media(max-width: 1000px) {
        width: 95%;
        font-size: 0.9em;
    }
`;

const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
`;

const BottomBox = styled.div`
    text-align: center;
    width: 100%;
`;

const BottomText = styled.h1`
    color: #0075a4;
    font-size: 1em;
    padding: 0;
    margin: 0.3em;
    cursor: pointer;
    @media(max-width: 1000px) {
        font-size: 0.9em;
    }
`;

const TitleFirst = styled.h1`
    color: #0075a4;
    font-size: 1.7em;
    margin-bottom: 1.3em;
    text-align: center;
`;

const TextFirst = styled.p`
    color: #0075a4;
    font-size: 1.1em;
    margin-top: 2em;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

`;

const TextFirstDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

